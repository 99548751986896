import React, { useState, useEffect, useCallback } from 'react';
import { FaPlus, FaEnvelope, FaArrowRight, FaStar, FaSearch, FaRegStar, FaUserFriends, FaUserCheck, FaDesktop, FaLink, FaCopy, FaDownload, FaTrash, FaFlag, FaSyncAlt} from 'react-icons/fa';
import { useHistory, useLocation } from 'react-router-dom';
import { getFirestore, doc, getDoc, setDoc, updateDoc, arrayUnion, deleteDoc, arrayRemove } from 'firebase/firestore/lite';
import { useAuth } from './authContext';
import { app } from './index';
import './BusinessesPage.css';
import ProfilePopup from './ProfilePopup';
import BusinessSidebar from './businessSidebar';
import AddJobPopup from './AddJobPopup'; // Import for reference, not used in Positions
import UploadEmailsPopup from './UploadEmailsPopup';
import ConfirmDeletePopup from './ConfirmDeletePopup';
import CandidateInfo from './candidateInfo';
import AccountPage from './accountPage';
import { collection, getDocs } from 'firebase/firestore/lite';
import OutreachSection from './OutreachSection';
import SettingsPopup from './SettingsPopup';
import HelpPopup from './HelpPopup';
import PositionsSection from './PositionsSection'; // Import the PositionsSection component
import { setDefaultEventParameters } from 'firebase/analytics';


function BusinessesPage() {
  const location = useLocation();
  const { selectedJob } = location.state || {}; 
  const { selectedResumeJob } = location.state || {}; 

  const [selectedJobRow, setSelectedJobRow] = useState(null);
  const [selectedResumeJobRow, setselectedResumeJobRow] = useState(null);
  const [selectedApplicantRows, setSelectedApplicantRows] = useState([]);
  const [selectedTab, setSelectedTab] = useState(0);
  const [filter, setFilter] = useState('overall');
  const [jobKeys, setJobKeys] = useState([]);
  const [resumeJobKeys, setResumeJobKeys] = useState([]);
  const [interviewees, setInterviewees] = useState({});
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [emailSentMessage, setEmailSentMessage] = useState('');
  const [isUploadPopupOpen, setIsUploadPopupOpen] = useState(false);
  // const [isProfilePopupOpen, setIsProfilePopupOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const { currentUser } = useAuth();
  const [company, setCompany] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const history = useHistory();
  const [isConfirmPopupOpen, setIsConfirmPopupOpen] = useState(false);
  const [jobToDelete, setJobToDelete] = useState(null);
  const [resumeJobToDelete, setResumeJobToDelete] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedCandidate, setSelectedCandidate] = useState(null);
  const [starredCandidates, setStarredCandidates] = useState({});
  const [totalCandidates, setTotalCandidates] = useState(0);
  const [completedInterviews, setCompletedInterviews] = useState(0);
  const [pendingInterviews, setPendingInterviews] = useState(0);
  let [personalityTraits, setPersonalityTraits] = useState([]);
  const [activeSection, setActiveSection] = useState('positions');
  const [label2, setlabel2] = useState(null);
  const [generatedLink, setGeneratedLink] = useState('');
  const [jobTitlesMap, setJobTitlesMap] = useState({});
  const [isPurdueUser, setIsPurdueUser] = useState(false);

/*
  useEffect(() => {
    localStorage.setItem('activeSection', activeSection);
  }, [activeSection]);
  useEffect(() => {
    const storedSection = localStorage.getItem('activeSection');
    if (storedSection) {
      setActiveSection(storedSection);
    } else {
      setActiveSection('positions'); // Default to 'positions'
    }
  }, []);
    */
  useEffect(() => {
    const checkPaymentStatus = async () => {
      if (!currentUser) {
        console.log('No user found, redirecting to home.');
        history.push('/'); // Redirect to home if not logged in
        return;
      }

      const db = getFirestore(app);
      const userDocRef = doc(db, 'businesses', currentUser.uid);
      const userDoc = await getDoc(userDocRef);

      if (!userDoc.exists()) {
        console.log('User document does not exist, redirecting to home.');
        history.push('/'); // Redirect to home if no document found
        return;
      }

      const userData = userDoc.data();
      if (!userData.stripePayment) {
        console.log('User has not paid, redirecting to home.');
        history.push('/subscription-page');
        return;
      }

      console.log('User has paid, loading data.');
      setJobKeys(userData.currentJobs || []);
      setResumeJobKeys(userData.currentResumeJobs || []);
      setCompany(userData.company || '');
      setName(userData.name || '');
      setEmail(userData.email || '');

      if (currentUser.email && currentUser.email.toLowerCase().endsWith('@purdue.edu')) {
        setIsPurdueUser(true);
      } else {
        setIsPurdueUser(false);
      }
    };

    checkPaymentStatus();
  }, [currentUser, history]);
  
  useEffect(() => {
    if (selectedJob && jobKeys.length > 0) {
      const index = jobKeys.indexOf(selectedJob);
      if (index !== -1) {
        setSelectedJobRow(index); // Set the selected job row based on the title
      }
    }
  }, [selectedJob, jobKeys]);

  useEffect(() => {
    const fetchJobTitles = async () => {
      const db = getFirestore(app);
      const newJobTitlesMap = {};
  
      // Fetch job titles for all jobKeys
      await Promise.all(
        jobKeys.map(async (key) => {
          const jobDescriptionRef = doc(db, 'businesses', currentUser.uid, key, 'jobDescription');
          const jobDescriptionDoc = await getDoc(jobDescriptionRef);
  
          if (jobDescriptionDoc.exists()) {
            const jobData = jobDescriptionDoc.data();
            newJobTitlesMap[key] = jobData.jobTitle || undefined;
          }
        })
      );

      await Promise.all(
        resumeJobKeys.map(async (key) => {
          const jobDescriptionRef = doc(db, 'businesses', currentUser.uid, key, 'jobDescription');
          const jobDescriptionDoc = await getDoc(jobDescriptionRef);
  
          if (jobDescriptionDoc.exists()) {
            const jobData = jobDescriptionDoc.data();
            newJobTitlesMap[key] = jobData.jobTitle || undefined;
          }
        })
      );

      console.log("newJobTitlesMap: ", newJobTitlesMap);
  
      setJobTitlesMap(newJobTitlesMap); // Update the state with fetched titles
    };
  
    if ((jobKeys.length > 0 || resumeJobKeys.length > 0) && currentUser) {
      fetchJobTitles();
    }
  }, [resumeJobKeys, jobKeys, currentUser]);

  const handleButtonClick = (buttonName, selectedJobKey = null, label = null) => {
    console.log(`${buttonName} button clicked`);
    if (buttonName === 'Outreach') {
      setIsUploadPopupOpen(true);
    } else {
      if (buttonName === 'Candidates' && selectedJobKey) {
        const index = label === 'resume screener' ? resumeJobKeys.indexOf(selectedJobKey) : jobKeys.indexOf(selectedJobKey);
        console.log(index, "button clicked", selectedJobKey);
        console.log(label);
        if (index !== -1) {
          label === 'resume screener' ? setselectedResumeJobRow(index) : setSelectedJobRow(index);
          label === 'resume screener' ? setlabel2('resume screener') : setlabel2(null);
        }
      }
      setActiveSection(buttonName.toLowerCase());
      setIsUploadPopupOpen(false); // Ensure the upload popup is closed if not Outreach
    }
  };
  

  const calculateAverageScore = (scores) => {
    if (!scores) return 0;
  
    let weightedScores = Object.keys(scores).map((trait) => {
      let weight = personalityTraits[trait] / 100;
      if (isNaN(weight)) {
        weight = 0;
      }
      return scores[trait] * weight;
    });
    console.log("got here")
    let totalWeight = Object.keys(personalityTraits).reduce((sum, trait) => {
      if (personalityTraits[trait]) {
        return sum + personalityTraits[trait];
      }
      return sum;
    }, 0);
    console.log("yeet")
    let totalScore = weightedScores.reduce((sum, score) => sum + score, 0);
    console.log("totalWeight: ", totalWeight);
    return totalWeight ? Math.round(totalScore / (totalWeight / 100)) : 0;
  };
  
  

  const sortedInterviewees = Object.keys(interviewees).map((key) => {
    const interviewee = interviewees[key];
    const averageScore = calculateAverageScore(interviewee.scores);
    console.log("averagescore: ", averageScore);
    return { ...interviewee, averageScore };
  }).sort((a, b) => {
    if (filter !== 'overall') {
      return (b.scores?.[filter] || 0) - (a.scores?.[filter] || 0);
    }
    return b.averageScore - a.averageScore;
  });

  const filteredInterviewees = sortedInterviewees.filter((interviewee) => {
    const matchesSearch = interviewee.name ? interviewee.name.toLowerCase().includes(searchQuery.toLowerCase()) : false;
    const matchesStarred = activeSection === 'starred' ? interviewee.starred : true; // Only show starred if in starred section
    return matchesSearch && matchesStarred;
  });  

  const handleRowClick = async (index) => {
    console.log("row clicked", index);
    console.log("interviewees object:", interviewees);
  
    const newSelectedRow = selectedRow === index ? null : index;
  
    if (newSelectedRow !== null) {
      const selectedInterviewee = filteredInterviewees[newSelectedRow];
      const intervieweeKey = Object.keys(interviewees).find(
        (key) => interviewees[key].submission_time === selectedInterviewee.submission_time
      );
  
      if (selectedInterviewee && intervieweeKey) {
        try {
          const db = getFirestore(app);
          const jobKey = getActiveJobKey();
          const intervieweeDocRef = doc(
            db,
            "businesses",
            currentUser.uid,
            jobKey,
            "interviewees"
          );
  
          // Update the 'opened' field in Firestore
          await updateDoc(intervieweeDocRef, {
            [`${intervieweeKey}.opened`]: true,
          });
  
          // Update the local state to remove boldness
          setInterviewees((prevInterviewees) => ({
            ...prevInterviewees,
            [intervieweeKey]: { ...prevInterviewees[intervieweeKey], opened: true },
          }));
        } catch (error) {
          console.error("Error updating opened field: ", error);
        }
      }
    }
  
    // Update selected row and candidate
    setSelectedRow(newSelectedRow);
  
    const newSelectedCandidate =
      newSelectedRow === null ? null : filteredInterviewees[newSelectedRow];
    const overallScore = newSelectedCandidate
      ? newSelectedCandidate.averageScore
      : null;
  
    setSelectedCandidate(
      newSelectedCandidate
        ? {
            ...newSelectedCandidate,
            overallScore,
            uid: newSelectedCandidate.uid,
            resumeURL: newSelectedCandidate.resumeURL,
          }
        : null
    );
  
    handleApplicantRowClick(index);
  };
  

  

  const handleStarClick = async (event, index) => {
    event.stopPropagation(); // Prevent the row click event from firing
  
    // Find the interviewee key based on the filtered interviewees
    const filteredIntervieweeKey = Object.keys(interviewees).find(key => interviewees[key].submission_time === filteredInterviewees[index].submission_time);
    const interviewee = interviewees[filteredIntervieweeKey];

    if (!interviewee || !interviewee.submission_time) {
      return;
    }
  
    if (interviewee) {
      const newStarredValue = !interviewee.starred;
      try {
        const db = getFirestore(app);
        const jobKey = getActiveJobKey();
        const intervieweeDocRef = doc(db, 'businesses', currentUser.uid, jobKey, 'interviewees');
  
        // Update the starred field in Firestore
        await updateDoc(intervieweeDocRef, {
          [`${filteredIntervieweeKey}.starred`]: newStarredValue
        });
  
        // Update the local state to reflect the change
        setInterviewees((prevInterviewees) => ({
          ...prevInterviewees,
          [filteredIntervieweeKey]: { ...interviewee, starred: newStarredValue },
        }));
      } catch (error) {
        console.error("Error updating starred field: ", error);
      }
    }
  };  

  const handleJobRowClick = (index) => {
    setSelectedJobRow(index);
    adjustDropdownWidth();
  };

  const handleResumeJobRowClick = (index) => {
    setselectedResumeJobRow(index);
    adjustResumeDropdownWidth();
  };
  
  const adjustDropdownWidth = () => {
    const selectElement = document.getElementById('position');
    if (selectElement) {
      selectElement.style.width = 'auto';
      const width = selectElement.scrollWidth;
      selectElement.style.width = `${width}px`;
    }
  };

  const adjustResumeDropdownWidth = () => {
    const selectElement = document.getElementById('resumePosition');
    if (selectElement) {
      selectElement.style.width = 'auto';
      const width = selectElement.scrollWidth;
      selectElement.style.width = `${width}px`;
    }
  };  

  useEffect(() => {
    adjustDropdownWidth();
  }, [jobKeys]);

  useEffect(() => {
    const checkUserDocument = async () => {
      if (!currentUser) {
        console.log('No current user, redirecting to sign-in.');
        history.push('/sign-in');
        return;
      }

      const db = getFirestore(app);
      const userDocRef = doc(db, 'businesses', currentUser.uid);
      const userDoc = await getDoc(userDocRef);

      if (!userDoc.exists()) {
        console.log('User document does not exist, redirecting to sign-in.');
        history.push('/sign-in');
      } else {
        const userData = userDoc.data();
        const jobs = userData.currentJobs || [];
        setJobKeys(jobs);

        const companyName = userData.company;
        const personName = userData.name;
        if (companyName) {
          setCompany(companyName);
        }
        if (personName) {
          setName(personName);
        }

        // Set initial selected job row and fetch interviewees
        if (jobs.length > 0) {
          setSelectedJobRow(0);
        }
      }
    };

    checkUserDocument();
  }, [currentUser, history]);

  const getActiveJobKey = () => {
    if (label2 === 'resume screener') {
      return resumeJobKeys[selectedResumeJobRow];
    } else {
      return jobKeys[selectedJobRow];
    }    
  };  

  useEffect(() => {
    console.log("Now activeSection is:", activeSection);
  }, [activeSection]);  

  useEffect(() => {
    console.log("Now label is:", label2);
  }, [label2]);  

  useEffect(() => {
    const fetchJobDescription = async () => {
      const rowIndex = (label2 === 'resume screener')
      ? selectedResumeJobRow
      : selectedJobRow;

      if (rowIndex !== null) {
        const db = getFirestore(app);
        const jobKey = getActiveJobKey();
        console.log("Fetching doc for jobKey:", jobKey);
        console.log("Doc path:", `businesses/${currentUser.uid}/${jobKey}/jobDescription`);
        const jobDescriptionRef = doc(db, 'businesses', currentUser.uid, jobKey, 'jobDescription');
        const jobDescriptionDoc = await getDoc(jobDescriptionRef);

        if (jobDescriptionDoc.exists()) {
          const jobDescriptionData = jobDescriptionDoc.data();

          const traits = jobDescriptionData.personalityTraits || {};
          if (typeof traits === 'object' && !Array.isArray(traits)) {
            try{
              console.log("setting personality triats")
              setPersonalityTraits(traits);
            }
            catch {
              console.log("couldn't set those traits")
            }
          } else {
            console.error('personalityTraits is not a dictionary:', traits);
          }

          if (jobDescriptionData.generatedLink) {
            setGeneratedLink(jobDescriptionData.generatedLink);
          } else {
            setGeneratedLink('');
          }
        } else {
          setGeneratedLink('');
        }
      }
    };
    fetchJobDescription();
  }, [selectedJobRow, selectedResumeJobRow, jobKeys, currentUser, label2]);

  const fetchInterviewees = async (
    selectedJobRow,
    jobKeys,
    currentUser,
    setInterviewees,
    setTotalCandidates,
    setCompletedInterviews,
    setPendingInterviews
  ) => {
    if (selectedJobRow !== null && jobKeys.length > 0 && currentUser) {
      const db = getFirestore(app);
      const jobKey = jobKeys[selectedJobRow];
      const jobDocRef = doc(db, 'businesses', currentUser.uid, jobKey, 'interviewees');
      const jobDoc = await getDoc(jobDocRef);
  
      if (jobDoc.exists()) {
        const intervieweesData = jobDoc.data();
  
        // Include UID and resumeURL in the interviewee data
        const intervieweesWithUid = Object.keys(intervieweesData).reduce((acc, key) => {
          acc[key] = { ...intervieweesData[key], uid: key, resumeURL: intervieweesData[key].resumeURL }; // Ensure resumeURL is included
          return acc;
        }, {});
  
        setInterviewees(intervieweesWithUid);
        setTotalCandidates(Object.keys(intervieweesWithUid).length);
  
        // Calculate completed and pending interviews
        let completedCount = 0;
        let pendingCount = 0;
        Object.values(intervieweesWithUid).forEach((interviewee) => {
          if (interviewee.scores) {
            completedCount++;
          } else {
            pendingCount++;
          }
        });
        setCompletedInterviews(completedCount);
        setPendingInterviews(pendingCount);
      }
    }
  };

  useEffect(() => {
    if (label2 !== 'resume screener' 
        && selectedJobRow !== null 
        && jobKeys.length > 0 
        && currentUser) {
      fetchInterviewees(
        selectedJobRow,
        jobKeys,
        currentUser,
        setInterviewees,
        setTotalCandidates,
        setCompletedInterviews,
        setPendingInterviews
      );
    }
  }, [label2, selectedJobRow, jobKeys, currentUser]);
  
  // Only fetch from screener if we ARE in resume screener mode
  useEffect(() => {
    if (label2 === 'resume screener' 
        && selectedResumeJobRow !== null 
        && resumeJobKeys.length > 0 
        && currentUser) {
      fetchResumeInterviewees(
        selectedResumeJobRow,
        resumeJobKeys,
        currentUser,
        setInterviewees,
        setTotalCandidates,
        setCompletedInterviews,
        setPendingInterviews
      );
    }
  }, [label2, selectedResumeJobRow, resumeJobKeys, currentUser]);
    
  const fetchResumeInterviewees = async (
    selectedResumeJobRow,
    resumeJobKeys,
    currentUser,
    setInterviewees,
    setTotalCandidates,
    setCompletedInterviews,
    setPendingInterviews
  ) => {
    if (selectedResumeJobRow !== null && resumeJobKeys.length > 0 && currentUser) {
      const db = getFirestore(app);
      const jobKey = resumeJobKeys[selectedResumeJobRow];
      const jobDocRef = doc(db, 'businesses', currentUser.uid, jobKey, 'interviewees');
      const jobDoc = await getDoc(jobDocRef);
  
      if (jobDoc.exists()) {
        const intervieweesData = jobDoc.data();
  
        // Include UID/resumeURL
        const intervieweesWithUid = Object.keys(intervieweesData).reduce((acc, key) => {
          acc[key] = {
            ...intervieweesData[key],
            uid: key,
            resumeURL: intervieweesData[key].resumeURL,
          };
          return acc;
        }, {});
  
        setInterviewees(intervieweesWithUid);
        setTotalCandidates(Object.keys(intervieweesWithUid).length);
  
        // Calculate completed/pending
        let completedCount = 0;
        let pendingCount = 0;
        Object.values(intervieweesWithUid).forEach((iv) => {
          if (iv.scores) completedCount++;
          else pendingCount++;
        });
        setCompletedInterviews(completedCount);
        setPendingInterviews(pendingCount);
      }
    }
  };  

  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp * 1000); // Convert to milliseconds
    return date.toLocaleString([], { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' });
  };

  const handleApplicantRowClick = (index) => {
    setSelectedApplicantRows((prevSelected) => {
      if (prevSelected.includes(index)) {
        return prevSelected.filter((i) => i !== index);
      } else {
        return [...prevSelected, index];
      }
    });

  };

  const handleAddJob = async (jobKey, jobDescription, customQuestions, personalityTraits) => {
    console.log("creating job")
    if (!currentUser) return;
  
    const db = getFirestore(app);
    const generatedLink = `https://safirahiring.com/welcomeinterview/${jobKey}/${currentUser.uid}`;
  
    // Convert personalityTraits to lowercase and create a map
    const personalityTraitsMap = {};
    for (const [trait, weighting] of Object.entries(personalityTraits)) {
      personalityTraitsMap[trait.toLowerCase()] = weighting;
    }
  
    const createdAt = new Date().toLocaleDateString('en-US', { month: 'long', day: '2-digit', year: 'numeric' });
  
    try {
      // Create a new document for the job with an empty 'interviewees' field
      const jobDocRef = doc(db, 'businesses', currentUser.uid, jobKey, 'interviewees');
      await setDoc(jobDocRef, {});
      console.log("done");
  
      const jobDesDocRef = doc(db, 'businesses', currentUser.uid, jobKey, 'jobDescription');
      await setDoc(jobDesDocRef, {
        generatedLink,
        jobDescription,
        customQuestions,
        personalityTraits: personalityTraitsMap,
        createdAt  // Added createdAt field
      });
      console.log("done2");
  
      // Update the currentJobs field to include the new job title
      const userDocRef = doc(db, 'businesses', currentUser.uid);
      await updateDoc(userDocRef, {
        currentJobs: arrayUnion(jobKey)
      });
  
      // Update the state with the new job title
      setJobKeys((prevjobKeys) => [...prevjobKeys, jobKey]);
      setIsPopupOpen(false);
      window.location.reload(); // Reload the whole page after adding the job
    } catch (error) {
      console.error("Error adding job: ", error);
    }
  };
  
  const handleAddResumeJob = async (jobKey, jobDescription, customQuestions, personalityTraits) => {
    if (!currentUser) return;
  
    const db = getFirestore(app);
    const generatedLink = `https://safirahiring.com/welcomeinterview/${jobKey}/${currentUser.uid}/016310`;
  
    // Convert traits to a lowercase-keyed map
    const personalityTraitsMap = {};
    for (const [trait, weighting] of Object.entries(personalityTraits)) {
      personalityTraitsMap[trait.toLowerCase()] = weighting;
    }
  
    const createdAt = new Date().toLocaleDateString('en-US', {
      month: 'long', day: '2-digit', year: 'numeric',
    });
  
    try {
      // Create an empty interviewees doc for this resume job
      const jobDocRef = doc(db, 'businesses', currentUser.uid, jobKey, 'interviewees');
      await setDoc(jobDocRef, {});
  
      // Create jobDescription doc for this resume job
      const jobDesDocRef = doc(db, 'businesses', currentUser.uid, jobKey, 'jobDescription');
      await setDoc(jobDesDocRef, {
        generatedLink,
        jobDescription,
        customQuestions,
        personalityTraits: personalityTraitsMap,
        createdAt,
      });
  
      // Update currentResumeJobs in the user document
      const userDocRef = doc(db, 'businesses', currentUser.uid);
      await updateDoc(userDocRef, {
        currentResumeJobs: arrayUnion(jobKey),
      });
  
      // Update local state
      setResumeJobKeys((prev) => [...prev, jobKey]);
      setIsPopupOpen(false);
      window.location.reload(); // Reload to force a fresh fetch
    } catch (error) {
      console.error("Error adding resume job:", error);
    }
  };  

  const handleTabClick = (index) => {
    setSelectedTab(index);
  };

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };

  const handleSearchChange = useCallback((event) => {
    setSearchQuery(event.target.value);
  }, []);

  const handleAddJobClick = () => {
    setIsPopupOpen(true);
  };

  const handleUploadEmailsClick = () => {
    setIsUploadPopupOpen(true);
  };

  const handlePopupClose = () => {
    setIsPopupOpen(false);
    setActiveSection('candidates');
  };



  const handleUploadPopupClose = () => {
    setIsUploadPopupOpen(false);
  };

  const handleConfirmDelete = async () => {
    if (jobToDelete !== null) {
      const db = getFirestore(app);
      const jobKey = jobKeys[jobToDelete];
      const jobDocRef = doc(db, 'businesses', currentUser.uid, jobKey, 'interviewees');
      await deleteDoc(jobDocRef);

      setJobKeys((prevjobKeys) => prevjobKeys.filter((_, index) => index !== jobToDelete));
      setJobToDelete(null);
      setIsConfirmPopupOpen(false);
    }
  };

  const handleConfirmResumeDelete = async () => {
    if (resumeJobToDelete !== null) {
      const db = getFirestore(app);
      const jobKey = resumeJobKeys[resumeJobToDelete];
      const jobDocRef = doc(db, 'businesses', currentUser.uid, jobKey, 'interviewees');
  
      await deleteDoc(jobDocRef);
  
      // Remove from state
      setResumeJobKeys((prev) => prev.filter((_, index) => index !== resumeJobToDelete));
  
      // Also remove from user document
      const userDocRef = doc(db, 'businesses', currentUser.uid);
      await updateDoc(userDocRef, {
        currentResumeJobs: arrayRemove(jobKey),
      });
    }
  };  

  const handleCancelDelete = () => {
    setIsConfirmPopupOpen(false);
    setJobToDelete(null);
  };

  const handleGenerateLinkClick = () => {
    window.location.reload();
  };

  const handleCopyLinkClick = () => {
    navigator.clipboard.writeText(generatedLink).then(() => {
      console.log('Link copied to clipboard');
    }).catch((error) => {
      console.error('Error copying link: ', error);
    });
  };
  const handleDownloadCSV = () => {
    const headers = "Name,Email\n";
    const csvContent = headers + filteredInterviewees.map(e => `${e.name},${e.email}`).join("\n");
    const jobKey = jobKeys[selectedJobRow].replace(/\s+/g, '-');
    const encodedUri = encodeURI("data:text/csv;charset=utf-8," + csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", `safira-${jobKey}-candidates.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleDeleteJobClick = () => {
    setIsConfirmPopupOpen(true);
  };

  const handleFooterClick = () => {
    console.log(name, company, email);
    history.push('/account-page', {
      name: name,
      company: company,
      email: email
    });
  };

  const handleFlagClick = (index) => {
    const interviewee = filteredInterviewees[index];
    if (interviewee.errorMessage) {
      alert(`Candidate reported error during interview:\n${interviewee.errorMessage}`);
    }
  };

  return (
    <div className={`businesses-page ${activeSection === 'starred' ? 'starred-candidates-page' : ''}`}>
      <BusinessSidebar handleButtonClick={handleButtonClick} currentUser={currentUser} company={company} activeSection={activeSection} name={name} handleFooterClick={handleFooterClick} />
  
      <main className="main-content">
        {activeSection === 'positions' || activeSection === 'resume screener' || activeSection === 'help' ? (
          <>
            {activeSection === 'help' && <HelpPopup />}
            {(activeSection === 'positions' || activeSection === 'resume screener') && (
              <PositionsSection
                jobKeys={activeSection === 'resume screener' ? resumeJobKeys : jobKeys}
                handleButtonClick={handleButtonClick}
                label={activeSection === 'resume screener' ? 'resume screener' : null}
                isPurdueUser={isPurdueUser}
              />
            )}
          </>
        ) : (
          <>
            {jobKeys.length === 0 && resumeJobKeys.length === 0 ? (
              <div className="empty-job-titles">
                <p>No Active Positions Currently</p>
              </div>
            ) : activeSection === 'outreach' ? (
              <OutreachSection
                jobKeys={jobKeys}
                emailSentMessage=""
                onClose={handleUploadPopupClose}
                onSubmit={() => {}}
              />
            ) : (
              <>
            <header className="main-header">
              {label2 === 'resume screener' ? (
                // If in Resume Screener tab, show a separate dropdown
                <div className="position-dropdown-container">
                  <label htmlFor="resumePosition">Resume Screener Position:</label>
                  <select
                    id="resumePosition"
                    value={selectedResumeJobRow ?? ''} // handle null with ''
                    onChange={(e) => handleResumeJobRowClick(e.target.value)}
                  >
                    {/* Display resumeJobKeys here */}
                    {resumeJobKeys.map((key, index) => (
                      <option key={index} value={index}>
                        {jobTitlesMap[key] || 'Untitled Resume Job'}
                      </option>
                    ))}
                  </select>
                </div>
              ) : (
                // If in normal "positions" or "candidates", show your existing dropdown
                <div className="position-dropdown-container">
                  <label htmlFor="position">Position:</label>
                  <select
                    id="position"
                    value={selectedJobRow ?? ''}
                    onChange={(e) => handleJobRowClick(e.target.value)}
                  >
                    {jobKeys.map((key, index) => (
                      <option key={index} value={index}>
                        {jobTitlesMap[key] || 'Loading...'}
                      </option>
                    ))}
                  </select>
                </div>
              )}

              {generatedLink ? (
                <div className="copy-link-button" onClick={handleCopyLinkClick}>
                  {`Copy Interview Link:`}&nbsp;&nbsp;
                  <div className='link-in-button'>
                    <p>{`${generatedLink.substring(0, 20)}...`}</p>
                  </div>
                  <FaCopy className="copy-icon" />
                </div>
              ) : (
                <div className="generate-link-button" onClick={handleGenerateLinkClick}>
                  <FaLink className="link-icon" />
                  Reload for link
                </div>
              )}
            </header>
                {activeSection !== 'starred' && (
                  <section className="stats-section">
                    <div className="stat-card">
                      <div className="stat-icon circle-icon"><FaUserFriends /></div>
                      <div className="stat-info">
                        <p>Total Candidates</p>
                        <h3>{totalCandidates}</h3>
                      </div>
                    </div>
                    <div className="stat-card">
                      <div className="stat-icon green-circle-icon"><FaUserCheck /></div>
                      <div className="stat-info">
                        <p>Completed Interviews</p>
                        <h3>{completedInterviews}</h3>
                      </div>
                    </div>
                    <div className="stat-card">
                      <div className="stat-icon yellow-circle-icon"><FaDesktop /></div>
                      <div className="stat-info">
                        <p>Pending Interviews</p>
                        <h3>{pendingInterviews}</h3>
                      </div>
                    </div>
                  </section>
                )}
                <section className="candidates-section">
                  <header className="candidates-header">
                    <div className="header-title">
                      <h1>
                        {activeSection === 'starred' ? 'Starred Candidates' : 'All Candidates'}
                        <button 
                            className="refresh-button" 
                            onClick={() => {
                              if (label2 === "resume screener") {
                                fetchInterviewees(
                                  selectedResumeJobRow,
                                  resumeJobKeys,
                                  currentUser,
                                  setInterviewees,
                                  setTotalCandidates,
                                  setCompletedInterviews,
                                  setPendingInterviews
                                );
                              } else {
                                fetchInterviewees(
                                  selectedJobRow,
                                  jobKeys,
                                  currentUser,
                                  setInterviewees,
                                  setTotalCandidates,
                                  setCompletedInterviews,
                                  setPendingInterviews
                                );
                              }
                            }} 
                            title="Refresh"
                          >
                            <FaSyncAlt />
                        </button>
                      </h1>
                    </div>
                    <div className="header-controls">
                      <div className="search-bar">
                        <input type="text" placeholder="Search..." value={searchQuery} onChange={handleSearchChange} />
                        <i className="search-icon fas fa-search"></i>
                      </div>
                      <div className="sort-dropdown-container">
                        <span>Sort by</span>
                        <select id="sort" value={filter} onChange={handleFilterChange}>
                          <option value="overall">Overall</option>
                          {Object.keys(personalityTraits).map((trait, index) => (
                            <option key={index} value={trait}>{trait}</option>
                          ))}
                        </select>
                      </div>
                      {activeSection === 'starred' && (
                        <button className="download-button" onClick={handleDownloadCSV}>
                          <FaDownload />
                        </button>
                      )}
                    </div>
                  </header>
                  <table className="candidates-table">
                    <thead>
                      <tr>
                        <th>Candidate Name</th>
                        <th>Starred</th>
                        <th>Score</th>
                        <th>Email</th>
                        <th>Completion Time</th>
                        <th>Status</th>
                        <th>Resume</th>
                      </tr>
                    </thead>
                    <tbody>
                      {filteredInterviewees.map((interviewee, index) => (
                        <tr
                          key={index}
                          onClick={() => handleRowClick(index)}
                          className={selectedRow === index ? 'selected-row' : ''}
                        >
                          <td
                            style={{
                              fontWeight: interviewee.opened || !interviewee.submission_time  ? 'normal' : 'bold',
                            }}
                          >
                            {interviewee.name}
                          </td>
                          <td>
                            <span onClick={(event) => handleStarClick(event, index)}>
                              {interviewee.starred ? <FaStar style={{ color: 'orange' }} /> : <FaRegStar />}
                            </span>
                          </td>
                          <td>{filter === 'overall' ? interviewee.averageScore : (interviewee.scores?.[filter] || '-')}</td>
                          <td>{interviewee.email}</td>
                          <td>
                            {interviewee.submission_time
                              ? formatTimestamp(interviewee.submission_time)
                              : '-'}
                          </td>
                          <td>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                              <span
                                className={`status-label ${
                                  interviewee.scores
                                    ? 'completed'
                                    : interviewee.interviewStarted
                                    ? 'in-progress'
                                    : 'not-started'
                                }`}
                              >
                                {interviewee.scores
                                  ? 'Completed'
                                  : interviewee.interviewStarted
                                  ? 'In Progress'
                                  : 'Not Started'}
                              </span>
                              {interviewee.errorMessage && (
                                <FaFlag
                                  style={{ color: 'red', marginLeft: '5px', cursor: 'pointer' }}
                                  onClick={(event) => {
                                    event.stopPropagation(); // Prevent row click event
                                    handleFlagClick(index);
                                  }}
                                />
                              )}
                            </div>
                          </td>
                          <td>
                            {interviewee.resumeURL ? (
                              <a
                                href={interviewee.resumeURL}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{ textDecoration: 'underline', color: 'blue' }}
                              >
                                View Resume
                              </a>
                            ) : (
                              'No Resume'
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>

                  </table>
                </section>

              </>
            )}
          </>
        )}
      </main>
      <AddJobPopup isOpen={isPopupOpen} onClose={handlePopupClose} onSubmit={handleAddJob} />
      <ConfirmDeletePopup
        isOpen={isConfirmPopupOpen}
        jobKey={jobKeys[selectedJobRow]}
        onCancel={handleCancelDelete}
        onConfirm={handleConfirmDelete}
      />
      {selectedCandidate && <CandidateInfo selectedCandidate={selectedCandidate} videos={selectedCandidate.videos} overallScore={selectedCandidate.overallScore} jobKey={getActiveJobKey()} resumeURL={selectedCandidate.resumeURL} label2={label2} />}
    </div>
  );
}

export default BusinessesPage;