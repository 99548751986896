import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { getFirestore, doc, getDoc } from 'firebase/firestore/lite';
import { app } from './index'; // Ensure correct Firebase import
import './candidate-result-public.css';

function CandidateResultPublic() {
  const location = useLocation();
  const pathParts = location.pathname.split('/');
  const resumeScreener = pathParts[5] === "016310";
  const [candidateData, setCandidateData] = useState(null);
  const [personalityTraits, setPersonalityTraits] = useState({});
  const [videoUrl, setVideoUrl] = useState(null);
  const [isVideoPopupVisible, setIsVideoPopupVisible] = useState(false);
  const [selectedTrait, setSelectedTrait] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const candidateInfoRef = useRef(null);

  const handlePlayClick = (index) => {
    if (!resumeScreener) {
      const videoIndex = Math.floor(index / 2);
      if (candidateData.videos && candidateData.videos[videoIndex]) {
        setVideoUrl(candidateData.videos[videoIndex]);
        setIsVideoPopupVisible(true);  
      }
    }
  };

  const closeVideoPopup = () => {
    setIsVideoPopupVisible(false);
    setVideoUrl(null);
  };

  // Extract businessUid and candidateUid from the URL
  const extractUidsFromUrl = () => {
    const pathSegments = location.pathname.split('/');
    const businessUid = pathSegments[2];
    const candidateUid = pathSegments[3];
    let jobTitle = pathSegments[4];
    jobTitle = jobTitle.replace(/-/g, ' ');

    return { businessUid, candidateUid, jobTitle };
  };

  useEffect(() => {
    const fetchCandidateData = async () => {
      const { businessUid, candidateUid, jobTitle } = extractUidsFromUrl();
  
      if (!businessUid || !candidateUid || !jobTitle) {
        setError('Invalid URL: Missing required parameters');
        setLoading(false);
        return;
      }
  
      try {
        const db = getFirestore(app);
        const intervieweesDocRef = doc(db, 'businesses', businessUid, jobTitle, 'interviewees');
        const intervieweesDoc = await getDoc(intervieweesDocRef);

        const jobDescriptionDocRef = doc(db, 'businesses', businessUid, jobTitle, 'jobDescription');
        const jobDescriptionDoc = await getDoc(jobDescriptionDocRef);

        if (intervieweesDoc.exists()) {
          const intervieweesData = intervieweesDoc.data();
          const jobDescriptionData = jobDescriptionDoc.data();
          if (intervieweesData[candidateUid]) {
            setPersonalityTraits(jobDescriptionData.personalityTraits || {});
            console.log("yee: ", personalityTraits);
            setCandidateData(intervieweesData[candidateUid]); // Access the candidate data from the map
          } else {
            setError('Candidate data not found');
          }
        } else {
          setError('Job or interviewees document not found');
        }
        console.log("candidate data fetched");
      } catch (err) {
        console.error('Error fetching candidate data:', err);
        setError('Error fetching candidate data');
      } finally {
        setLoading(false);
      }
    };
  
    fetchCandidateData();
  }, [location]);  

    // Weighted average calculation
    const calculateWeightedAverage = (scores, personalityTraits) => {
      if (!scores || !personalityTraits) {
        return 0;
      }
  
      let weightedScores = Object.keys(scores).map((trait) => {
        let weight = personalityTraits[trait] / 100;
        if (isNaN(weight)) weight = 0;
        return scores[trait] * weight;
      });
  
      let totalWeight = Object.keys(personalityTraits).reduce((sum, trait) => {
        if (personalityTraits[trait]) return sum + personalityTraits[trait];
        return sum;
      }, 0);
  
      let totalScore = weightedScores.reduce((sum, score) => sum + score, 0);
      return totalWeight ? Math.round(totalScore / (totalWeight / 100)) : 0;
    };  

    const overallScore = useMemo(() => {
      if (candidateData && candidateData.scores) {
        return calculateWeightedAverage(candidateData.scores, personalityTraits);
      }
      return 0;
    }, [candidateData, personalityTraits]);


  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  if (!candidateData) return null;
  console.log(candidateData);

  const scores = candidateData.scores || {};
  const traits = Object.keys(scores);
  const traitColors = ['#D1DCF3', '#FFD07A', '#CEED89', '#FDCFE9', '#FF8440', '#94FFFF'];
  const skills = candidateData.skills || [];
  const resumeURL = candidateData.resumeURL;

  const showResumes = () => {
    if (resumeURL) {
      const resumeURL = candidateData.resumeURL;
      window.open(resumeURL, '_blank');
    } else {
      alert('No Resume On File');
    }
  };

  return (
    <div className="candidate-result-public" ref={candidateInfoRef}>
      <div className="candidate-info-header-public">
        <h2 style={{ fontSize: '1.2em' }}>{candidateData.name}</h2>
        <p onClick={showResumes} style={{ color: 'blue', textDecoration: 'underline', cursor: 'pointer', fontSize: '0.5em', marginBottom: '15px' }}>
          View Resume
        </p>

        <div className="overall-score-public">
          <div className="score-circle overall-circle-public" style={{ backgroundColor: '#FFE4C7', color: '#000', fontWeight: 'bold', width: '120px', height: '120px', fontSize: '1.5em' }}>
            {overallScore}
          </div>
          <p>Overall</p>
          <div className="horizontal-circles-public">
            {traits.slice(0, 6).map((trait, index) => (
              <div
                className="circle-with-word"
                key={index}
                onClick={() => setSelectedTrait(selectedTrait === trait ? null : trait)}
                style={{ cursor: 'pointer' }}
              >
                <div className="score-circle small-circle" style={{ backgroundColor: traitColors[index % traitColors.length], fontSize: '0.6em', border: selectedTrait === trait ? '2px solid #FF6B19' : 'none' }}>
                  {scores[trait] || '00'}
                </div>
                <p className="trait-word" style={{ fontSize: '0.7em' }}>{trait.charAt(0).toUpperCase() + trait.slice(1)}</p>
              </div>
            ))}
          </div>
            {/* Update the skills section to use selectedCandidate.skills */}
            { skills && skills.length > 0 && (
              <div className="skills-container">
                {candidateData.skills.map((skill, index) => (
                  <span key={index} className="skill-box">
                    {skill}
                  </span>
                ))}
              </div>
            )}
        </div>
      </div>
      <hr />
      <div className="candidate-info-feedback">
        <h3 style={{ fontSize: '1.5em' }}>Feedback</h3>
        <div className="feedback-content-public">
          {selectedTrait ? (
            <p><strong>{selectedTrait.charAt(0).toUpperCase() + selectedTrait.slice(1)}:</strong> {candidateData.feedback ? candidateData.feedback[selectedTrait] : 'No feedback available.'}</p>
          ) : (
            <p style={{ color: 'gray', textAlign: 'center' }}>Click a trait to see feedback</p>
          )}
        </div>
      </div>
      <hr />
      {!resumeScreener && (
          <div className="candidate-info-transcript-public">
          <h3 style={{ fontSize: '1.5em' }}>Transcript</h3>
          {candidateData.transcript ? (
            candidateData.transcript
              .filter(item => item.role === 'assistant' || item.role === 'user')
              .map((item, index) => (
                <div className={`bubbletemplate-public ${item.role === 'assistant' ? 'ai' : 'human'}`} key={index}>
                  {item.role === 'user' && (index % 2 !== 0) && (
                    <button className="play-button-speech-public" onClick={() => handlePlayClick(index)}>
                      &#9658;
                    </button>
                  )}
                  {item.content}
                </div>
              ))
          ) : (
            <div>No transcript available.</div>
          )}
        </div>
      )}
      {isVideoPopupVisible && (
        <div className="video-popup-public" onClick={closeVideoPopup}>
          <div className="video-popup-content-public" onClick={(e) => e.stopPropagation()}>
            <video controls autoPlay src={videoUrl} />
          </div>
        </div>
      )}
    </div>
  );
}

export default CandidateResultPublic;
