import React from "react";
import SwaggerUI from "swagger-ui-react";
import "swagger-ui-react/swagger-ui.css";
import "./api-docs.css";

const ApiDocumentation = () => {
  const serverLink = "https://fractalflaskapp-5c91849aadae.herokuapp.com/safira-data-api";

  const handleCopy = () => {
    navigator.clipboard.writeText(serverLink);
    alert("Server URL copied to clipboard!");
  };

  return (
    <div className="api-documentation-page">
      <div className="api-documentation-container">
        <div className="server-copy-container">
          <label htmlFor="server-url" className="server-label">Server URL:</label>
          <input
            id="server-url"
            type="text"
            value={serverLink}
            readOnly
            className="server-input"
          />
          <button onClick={handleCopy} className="copy-button">
            Copy
          </button>
        </div>
        <SwaggerUI url="/NIKUNJT0-safira-hiring_api-1.0.0-resolved.json" />
      </div>
    </div>
  );
};

export default ApiDocumentation;
