import React from 'react';
import './cancelMembership.css';

const CancelMembership = () => {
  return (
    <div className="cancel-membership-page">
      <h1>Thanks for being part of the Safira family!</h1>
      <p>We hope we'll see you again!</p>
    </div>
  );
};

export default CancelMembership;
