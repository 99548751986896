import React, { useState, useEffect } from 'react';
import './AddJobPopup.css'; 
import PreviewPopup from './PreviewPopup'; // Import the new PreviewPopup component
import { useAuth } from './authContext';
import { app } from './index';
import { getFirestore, doc, setDoc, updateDoc, arrayUnion } from 'firebase/firestore/lite';

function generateRandomKey(length = 16) {
  const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let key = '';
  for (let i = 0; i < length; i++) {
    key += chars.charAt(Math.floor(Math.random() * chars.length));
  }
  return key;
}

const AddJobPopup = ({
  isOpen,
  onClose,
  onSubmit,
  editData,
  existingJobKeys,
  label
}) => {
  const { currentUser } = useAuth();

  const [jobTitle, setJobTitle] = useState('');
  const [jobKey, setJobKey] = useState('');
  const [timeLimit, setTimeLimit] = useState('1:30');
  const [jobDescription, setJobDescription] = useState('');
  const [scoringCriteria, setScoringCriteria] = useState([
    { skill: 'Technical', weighting: 20 },
    { skill: 'Communication', weighting: 20 },
    { skill: 'Leadership', weighting: 20 },
    { skill: 'Social', weighting: 20 },
    { skill: 'Grit', weighting: 20 }
  ]);
  const [requireCamera, setRequireCamera] = useState(true);
  const [requireResume, setRequireResume] = useState(true);
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);

  // If you need customQuestions / aiQuestions for Resume Screener,
  // you can store them in local state similarly to the PreviewPopup logic.
  // For now, we'll assume they are empty arrays for a new Resume Screener job:
  const customQuestions = editData ? editData.customQuestions : [];
  const aiQuestions = editData ? Array(editData.aiQuestions).fill('AI Question') : Array(3).fill('AI Question');

  useEffect(() => {
    if (editData) {
      setJobTitle(editData.jobTitle);
      setJobKey(editData.jobKey);
      setJobDescription(editData.jobDescription);
      setScoringCriteria(editData.personalityTraits);
      setTimeLimit(editData.timeLimit ? editData.timeLimit : '1:30');
      setRequireCamera(editData.requireCamera !== undefined ? editData.requireCamera : true);
      setRequireResume(editData.requireResume !== undefined ? editData.requireResume : true);
    } else {
      // Reset to defaults for a new job
      setJobTitle('');
      setJobDescription('');
      setScoringCriteria([
        { skill: 'Technical', weighting: 20 },
        { skill: 'Communication', weighting: 20 },
        { skill: 'Leadership', weighting: 20 },
        { skill: 'Social', weighting: 20 },
        { skill: 'Grit', weighting: 20 },
      ]);
      setTimeLimit('1:30');
      setRequireCamera(true);
      setRequireResume(true);
    }
  }, [editData]);

  const handleCriteriaChange = (index, field, value) => {
    const updatedCriteria = [...scoringCriteria];
    if(field === 'weighting'){
      value = parseInt(value);
    }
    if (field === 'skill') {
      value = value.replace(/ /g, '-');
    }
    updatedCriteria[index][field] = value;
    setScoringCriteria(updatedCriteria);
  };

  /** Directly submit to Firestore if label is 'Resume Screener' */
  const directSubmitForResumeScreener = async () => {
    // Basic validations (already done in handleNext, but you can replicate if needed)
    if (!currentUser) return;

    const scoringCriteriaMap = {};
    scoringCriteria.forEach((criteria) => {
      scoringCriteriaMap[criteria.skill.toLowerCase()] = criteria.weighting;
    });

    // We replicate the logic from PreviewPopup.handleSubmit:
    const db = getFirestore(app);
    // We'll generate a new random key for this "Resume Screener" job
    const randomJobKey = generateRandomKey();

    // Construct the link the same way
    const generatedLink = `https://safirahiring.com/welcomeinterview/${randomJobKey}/${currentUser.uid}/016310`;

    // Some metadata (createdAt, etc.)
    const createdAt = new Date().toLocaleDateString('en-US', {
      month: 'long',
      day: '2-digit',
      year: 'numeric'
    });

    try {
      if (editData) {
        // If we are editing an existing "Resume Screener" job
        const jobDesDocRef = doc(db, 'businesses', currentUser.uid, jobKey, 'jobDescription');
        await updateDoc(jobDesDocRef, {
          jobTitle,
          jobDescription,
          customQuestions,
          timeLimit,
          requireCamera,
          requireResume,
          aiQuestions: aiQuestions.length, // store the count
          personalityTraits: scoringCriteriaMap
        });
      } else {
        // Create a brand-new "Resume Screener" job
        const jobDocRef = doc(db, 'businesses', currentUser.uid, randomJobKey, 'interviewees');
        await setDoc(jobDocRef, {}); // empty interviewees collection

        const jobDesDocRef = doc(db, 'businesses', currentUser.uid, randomJobKey, 'jobDescription');
        await setDoc(jobDesDocRef, {
          generatedLink,
          timeLimit,
          requireCamera,
          requireResume,
          jobDescription,
          customQuestions,
          aiQuestions: aiQuestions.length,
          personalityTraits: scoringCriteriaMap,
          createdAt,
          jobTitle
        });

        // Update user doc to include new jobKey in 'currentJobs'
        // or 'currentResumeJobs' if you want a separate field for Resume Screener
        const userDocRef = doc(db, 'businesses', currentUser.uid);
        await updateDoc(userDocRef, {
          currentResumeJobs: arrayUnion(randomJobKey)
        });
      }

      // Force a refresh to see the newly added job
      window.location.reload();
    } catch (error) {
      console.error("Error adding resume screener job: ", error);
    }
    onClose();
  };

  /** 
   * Called when the user clicks the "Next" or "Submit" button. 
   * For normal jobs => Open Preview Popup.
   * For "Resume Screener" => Skip preview and submit directly.
   */
  const handleNext = () => {
    // Basic checks
    if (jobTitle.trim() === '' || jobDescription.trim() === '') {
      alert('Please fill out all fields');
      return;
    }

    if (!editData && existingJobKeys.includes(jobTitle)) {
      alert('A job with this title already exists. Please choose a different title.');
      return;
    }

    const totalWeighting = scoringCriteria.reduce(
      (total, criteria) => total + parseFloat(criteria.weighting),
      0
    );
    if (totalWeighting !== 100) {
      alert('The total weighting must add up to 100%');
      return;
    }

    setIsPreviewOpen(true);
  };

  const handlePreviewClose = () => {
    setIsPreviewOpen(false);
  };

  if (!isOpen) return null;

  return (
    <div className="popup-overlay">
      <div className="popup-content">
        <h2 className="popup-heading">
          {editData ? 'Edit Job Details' : 'Add Job Details'}
        </h2>
        <form>
          <div className="job-title-time-container">
            <input
              type="text"
              value={jobTitle}
              onChange={(e) => setJobTitle(e.target.value)}
              placeholder="Enter Job Title"
              className="job-title-input"
            />
            {/* Hide timeLimit if it's a Resume Screener */}
            {label !== 'resume screener' && (
              <div className="time-input-container">
                <h3 className="popup-heading-time">Time Limit per Response:</h3>
                <div className="time-input-wrapper">
                  <input
                    type="text"
                    value={timeLimit}
                    onChange={(e) => {
                      let value = e.target.value.replace(/[^0-9]/g, '');
                      if (value.length > 3) {
                        value = value.slice(-3);
                      }
                      if (value.length <= 2) {
                        value = value.padStart(2, '0');
                        value = '0:' + value;
                      } else {
                        value = value.slice(0, value.length - 2) + ':' + value.slice(-2);
                      }
                      setTimeLimit(value);
                    }}
                    placeholder="0:00"
                    className="time-limit-input"
                  />
                  <span className="time-unit">mins</span>
                </div>
              </div>
            )}
          </div>

          <textarea
            value={jobDescription}
            onChange={(e) => setJobDescription(e.target.value)}
            placeholder="Paste Job Description"
            className="job-description-input"
          />

          <h3 className="popup-heading">Add Scoring Criteria</h3>
          <div className="scoring-criteria-header">
            <span className="label-text">Skill</span>
            <span className="label-text">Weight Percentage</span>
          </div>
          {scoringCriteria.map((criteria, index) => (
            <div className="skill-row" key={index}>
              <input
                type="text"
                value={criteria.skill}
                onChange={(e) => handleCriteriaChange(index, 'skill', e.target.value)}
                className="skill-input"
                disabled={!!editData}
              />
              <input
                type="number"
                value={criteria.weighting}
                onChange={(e) => handleCriteriaChange(index, 'weighting', e.target.value)}
                className="weighting-input"
              />
            </div>
          ))}

          {/* Hide camera/resume checkboxes if label is 'Resume Screener' */}
          {label !== 'resume screener' && (
            <>
              <div className="checkbox-container">
                <input
                  type="checkbox"
                  id="require-camera-checkbox"
                  checked={requireCamera}
                  onChange={(e) => setRequireCamera(e.target.checked)}
                />
                <label htmlFor="require-camera-checkbox">
                  Require candidate's camera to be on
                </label>
              </div>

              <div className="checkbox-container">
                <input
                  type="checkbox"
                  id="require-resume-checkbox"
                  checked={requireResume}
                  onChange={(e) => setRequireResume(e.target.checked)}
                />
                <label htmlFor="require-resume-checkbox">
                  Require candidate to submit resume (recommended)
                </label>
              </div>
            </>
          )}

          <div className="popup-buttons">
            <button type="button" className="close-button" onClick={onClose}>
              Close
            </button>
            <button type="button" className="next-button" onClick={handleNext}>
              Next
            </button>
          </div>
        </form>
      </div>

      {/* We still render PreviewPopup, but only open it if label !== 'Resume Screener' */}
      <PreviewPopup
        isOpen={isPreviewOpen}
        onClose={handlePreviewClose}
        jobTitle={jobTitle}
        jobKey={jobKey}
        timeLimit={timeLimit}
        jobDescription={jobDescription}
        scoringCriteria={scoringCriteria}
        customQuestions={customQuestions}
        aiQuestions={aiQuestions}
        requireCamera={requireCamera}
        requireResume={requireResume}
        onSubmit={onSubmit}
        isEditing={editData}
        label={label}
      />
    </div>
  );
};

export default AddJobPopup;
