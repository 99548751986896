import React, { createContext, useContext, useState } from 'react';

// 1. Create Context
const ResumeContext = createContext();

// 2. Export a custom hook to use this context easily
export const useResume = () => useContext(ResumeContext);

// 3. Create the Provider component
export const ResumeProvider = ({ children }) => {
  const [resumeFile, setResumeFile] = useState(null);

  return (
    <ResumeContext.Provider value={{ resumeFile, setResumeFile }}>
      {children}
    </ResumeContext.Provider>
  );
};
