import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { getFirestore, doc, getDoc, updateDoc, deleteField } from 'firebase/firestore';
import { v4 as uuidv4 } from 'uuid';
import './welcomeBusinessInterview.css';
import { app } from './index'; // Assuming you have initialized Firebase in index.js

const NameEmail = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [showNextButton, setShowNextButton] = useState(false);
  const [fadeOut, setFadeOut] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const history = useHistory();
  const location = useLocation();
  const db = getFirestore(app);

  useEffect(() => {
    setShowNextButton(name.trim() !== '' && email.trim() !== '');
  }, [name, email]);

  const handleNext = async () => {
    setFadeOut(true);
    const pathParts = location.pathname.split('/');
    const businessUID = pathParts[3];
    const jobKey = pathParts[2];
    const whiteLabel = pathParts[pathParts.length - 1] === "ai-interview";
    const resumeScreener = pathParts[4] === "016310";
    const intervieweeID = uuidv4();
    const formattedJobKey = jobKey.replace(/-/g, ' ');
  
    try {
      const intervieweeDocRef = doc(
        db,
        `businesses/${businessUID}/${formattedJobKey}/interviewees`
      );
      const intervieweeDoc = await getDoc(intervieweeDocRef);
  
      // Determine if the entered email is a Purdue email.
      const isPurdueEmail = email.toLowerCase().endsWith('@purdue.edu');
  
      if (intervieweeDoc.exists()) {
        const interviewees = intervieweeDoc.data();
        for (const id in interviewees) {
          const interviewee = interviewees[id];
          if (interviewee.email === email && id !== intervieweeID) {
            // Only delete the existing record if it's not a Purdue email.
            if (!isPurdueEmail) {
              console.log("deleting old one");
              await updateDoc(intervieweeDocRef, {
                [id]: deleteField()
              });
            }
          }
        }
      }
  
      await updateDoc(intervieweeDocRef, {
        [intervieweeID]: {
          name,
          email
        }
      });
  
      setTimeout(() => {
        const newPath = `/uploadresume/${businessUID}/${formattedJobKey}/${intervieweeID}${
          resumeScreener ? '/016310' : ''
        }${whiteLabel ? '/ai-interview' : ''}`;
        console.log(`Navigating to ${newPath}`);
        history.push(newPath, {
          interviewId: intervieweeID,
          jobKey,
          businessUID,
          resumeScreener
        });
      }, 1000);
    } catch (error) {
      console.error("Error adding document: ", error);
    }
  };
  

  return (
    <div className="welcome-container">
      <div className={`input-container ${fadeOut ? 'fade-out' : ''}`}>
        <h1 className={`welcome-text ${fadeOut ? 'fade-out' : ''}`}>
          {"Please enter your name and email.".split(" ").map((word, wordIndex) => (
            <span key={wordIndex} className="fade-in-char" style={{ animationDelay: `${wordIndex * 0.02}s` }}>
              {word}&nbsp;
            </span>
          ))}
        </h1>
        <h3 className={`welcome-text ${fadeOut ? 'fade-out' : ''}`} style={{ fontSize: '0.8em' }}>
          {"Enter the same email that you are applying to the job with.".split(" ").map((word, wordIndex) => (
            <span key={wordIndex} className="fade-in-char" style={{ animationDelay: `${(0.5) + (wordIndex * 0.02)}s` }}>
              {word}&nbsp;
            </span>
          ))}
        </h3>
        <input
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder="Enter your name"
          className="input-field"
        />
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Enter your email"
          className="input-field"
        />
        {errorMessage && <p className="error-message">{errorMessage}</p>}
        {showNextButton && (
          <button
            type="button"
            className="interview-next-button name-email"
            onClick={handleNext}
          >
            Next
          </button>
        )}
      </div>
    </div>
  );
};

export default NameEmail;