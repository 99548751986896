import React, { useState, useEffect } from 'react';
import { getFirestore, doc, updateDoc, getDoc } from 'firebase/firestore/lite';
import { useHistory } from 'react-router-dom';
import { getAuth, signOut } from 'firebase/auth';  // Import signOut from Firebase Authentication
import './ProfilePopup.css';
import { app } from './index';

const ProfilePopup = ({ name, company, email, onClose, currentUser }) => {
  const [editedName, setEditedName] = useState(name);
  const [editedCompany, setEditedCompany] = useState(company);
  const [editedEmail, setEditedEmail] = useState(email);
  const [stripePayment, setStripePayment] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const [showCancelConfirmation, setShowCancelConfirmation] = useState(false);
  const [showSaveConfirmation, setShowSaveConfirmation] = useState(false);
  const [showLogoutConfirmation, setShowLogoutConfirmation] = useState(false);
  const [showChangePasswordPopup, setShowChangePasswordPopup] = useState(false);  // New state for change password popup
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');

  const history = useHistory();

  useEffect(() => {
    setEditedName(name);
    setEditedCompany(company);
    setEditedEmail(email);

    const fetchStripePaymentStatus = async () => {
      if (!currentUser) return;
      const db = getFirestore(app);
      const userDocRef = doc(db, 'businesses', currentUser.uid);
      const userDoc = await getDoc(userDocRef);

      if (userDoc.exists()) {
        const userData = userDoc.data();
        setStripePayment(userData.stripePayment || false);
      }
    };

    fetchStripePaymentStatus();
  }, [name, company, email, currentUser]);

  const handleInputChange = (field, value) => {
    setIsChanged(true);
    if (field === 'name') setEditedName(value);
    if (field === 'company') setEditedCompany(value);
    if (field === 'email') setEditedEmail(value);
  };

  const handleSave = async () => {
    if (!isChanged || !currentUser) return;

    const db = getFirestore(app);
    const userDocRef = doc(db, 'businesses', currentUser.uid);

    try {
      const updates = {};
      if (editedName !== name) updates.name = editedName;
      if (editedCompany !== company) updates.company = editedCompany;
      if (editedEmail !== email) updates.email = editedEmail;

      if (Object.keys(updates).length > 0) {
        await updateDoc(userDocRef, updates);
        setIsChanged(false);
        onClose(); // Close the popup after saving
        setShowSaveConfirmation(false); // Close the confirmation popup
      }
    } catch (error) {
      console.error('Error updating profile:', error);
    }
  };

  const handleLogout = async () => {
    try {
      const auth = getAuth(app);
      await signOut(auth);  // Log out the user
      history.push('/login');  // Redirect to the login page (or another page as needed)
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  const handleCancelMembership = async () => {
    if (!currentUser) return;
  
    const db = getFirestore(app);
    const userDocRef = doc(db, 'businesses', currentUser.uid);
    const cancelledMembershipsRef = doc(db, 'cancelledMemberships', 'businessIDs');
  
    try {
      await updateDoc(userDocRef, { stripePayment: false });
  
      await updateDoc(cancelledMembershipsRef, {
        [currentUser.uid]: true,
      });
  
      history.push('/cancel-membership');
    } catch (error) {
      console.error('Error cancelling membership:', error);
    }
  };

  const handleClose = () => {
    if (isChanged) {
      setShowSaveConfirmation(true);  // Show save confirmation if there are changes
    } else {
      onClose();  // If no changes, just close the popup
    }
  };

  const handleConfirmSave = () => {
    handleSave();
    setShowSaveConfirmation(false);  // Close the confirmation popup after saving
  };

  const handleCancelSave = () => {
    setShowSaveConfirmation(false);  // Close the confirmation popup without saving
  };

  const handleConfirmLogout = () => {
    handleLogout();
    setShowLogoutConfirmation(false); // Close the logout confirmation popup
  };

  const handleCancelLogout = () => {
    setShowLogoutConfirmation(false); // Close the logout confirmation popup
  };

  // Handle opening and closing the change password popup
  const handleChangePassword = () => {
    setShowChangePasswordPopup(true); // Open the change password popup
  };

  const handleCancelChangePassword = () => {
    setShowChangePasswordPopup(false); // Close the change password popup
    setOldPassword('');
    setNewPassword('');
    setConfirmNewPassword('');
  };

  return (
    <div className="profile-popup">
      <div className="profile-popup-content">
        {/* X button with functionality to close the popup or show save confirmation */}
        <button className="close-x-button" onClick={handleClose}>X</button>

        {/* Logout Button with confirmation popup */}
        <button className="logout-button" onClick={() => setShowLogoutConfirmation(true)}>Logout</button>

        <h2>Profile</h2>
        <div className="profile-field">
          <label htmlFor="name">Name:</label>
          <input
            id="name"
            type="text"
            value={editedName}
            onChange={(e) => handleInputChange('name', e.target.value)}
          />
        </div>
        <div className="profile-field">
          <label htmlFor="company">Company:</label>
          <input
            id="company"
            type="text"
            value={editedCompany}
            onChange={(e) => handleInputChange('company', e.target.value)}
          />
        </div>
        <div className="profile-field">
          <label htmlFor="email">Email:</label>
          <input
            id="email"
            type="text"
            value={editedEmail}
            onChange={(e) => handleInputChange('email', e.target.value)}
          />
        </div>
        {stripePayment && (
          <button
            className="cancel-membership-button"
            onClick={() => setShowCancelConfirmation(true)}
          >
            Cancel Membership
          </button>
        )}
        {/* Change Password Button */}
        <button className="change-password-button" onClick={handleChangePassword}>
          Change Password
        </button>
      </div>

      {/* Save Confirmation Popup */}
      {showSaveConfirmation && (
        <div className="confirmation-popup">
          <div className="confirmation-content">
            <h3>Confirm Save</h3>
            <p>Would you like to save your changes?</p>
            <div className="confirmation-buttons">
              <button onClick={handleCancelSave} className="cancel-button">
                Cancel
              </button>
              <button onClick={handleConfirmSave} className="proceed-button">
                Save Changes
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Logout Confirmation Popup */}
      {showLogoutConfirmation && (
        <div className="confirmation-popup">
          <div className="confirmation-content">
            <h3>Confirm Logout</h3>
            <p>Are you sure you want to log out?</p>
            <div className="confirmation-buttons">
              <button onClick={handleCancelLogout} className="cancel-button">
                Cancel
              </button>
              <button onClick={handleConfirmLogout} className="proceed-button">
                Log Out
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Change Password Popup */}
      {showChangePasswordPopup && (
        <div className="confirmation-popup">
          <div className="confirmation-content">
            <h3>Change Password</h3>
            <div className="profile-field">
              <label htmlFor="oldPassword">Old Password:</label>
              <input
                id="oldPassword"
                type="password"
                value={oldPassword}
                onChange={(e) => setOldPassword(e.target.value)}
              />
            </div>
            <div className="profile-field">
              <label htmlFor="newPassword">New Password:</label>
              <input
                id="newPassword"
                type="password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
              />
            </div>
            <div className="profile-field">
              <label htmlFor="confirmNewPassword">Confirm New Password:</label>
              <input
                id="confirmNewPassword"
                type="password"
                value={confirmNewPassword}
                onChange={(e) => setConfirmNewPassword(e.target.value)}
              />
            </div>
            <div className="confirmation-buttons">
              <button onClick={handleCancelChangePassword} className="cancel-button">
                Cancel
              </button>
              <button className="proceed-button">
                Submit (Not Implemented)
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProfilePopup;
