import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import ConfettiExplosion from 'react-confetti-explosion';
import './welcomeBusinessInterview.css';
import './App.css';

const InterviewDone = () => {
  const location = useLocation();
  const pathParts = location.pathname.split('/');
  const resumeScreener = pathParts[2] === "016310";

  const [isExploding, setIsExploding] = useState(false);

  useEffect(() => {
    // Trigger confetti explosion
    setIsExploding(true);

    // Optionally stop exploding after some time
    const timer = setTimeout(() => {
      setIsExploding(false);
    }, 5000);

    return () => clearTimeout(timer); // Cleanup timer
  }, []);

  useEffect(() => {
    // Hide the menu bar when the component is mounted
    const navbar = document.querySelector('.menu-bar');
    if (navbar) {
      navbar.classList.add('hide');
    }

    return () => {
      if (navbar) {
        navbar.classList.remove('hide');
      }
    };
  }, []);

  return (
    <div className="interview-done-container">
      <div className="interview-content">
        {isExploding && <ConfettiExplosion />}
        <h1>Congrats, you’ve completed the {resumeScreener ? 'application' : 'interview'}!</h1>
        <p>
          Thank you for taking the time out of your day! A company representative will be
          reaching out to you with results soon.
        </p>
        <p>You can close out of this tab now. Thanks!</p>
      </div>
    </div>
  );
};

export default InterviewDone;