import React, { useState, useEffect, useRef } from 'react';
import { getFirestore, doc, updateDoc, getDoc } from 'firebase/firestore/lite';
import { getAuth, signOut } from 'firebase/auth';
import { useHistory } from 'react-router-dom';
import { useAuth } from './authContext';
import './accountPage.css';
import { app } from './index';

const AccountPage = ({ name, company, email }) => {
  const [editedName, setEditedName] = useState(name);
  const [editedCompany, setEditedCompany] = useState(company);
  const [editedEmail, setEditedEmail] = useState(email);
  const [isChanged, setIsChanged] = useState(false);
  const [showSaveConfirmation, setShowSaveConfirmation] = useState(false);
  const [showLogoutConfirmation, setShowLogoutConfirmation] = useState(false);
  const [showCancelPopup, setShowCancelPopup] = useState(false);
  const [cancelReason, setCancelReason] = useState('');
  const stripePayment = useRef(false);
  const { currentUser } = useAuth();
  const history = useHistory();

  // Extract the account creation year
  const accountCreationYear = currentUser?.metadata?.creationTime
    ? new Date(currentUser.metadata.creationTime).getFullYear()
    : 'Not Available';

  useEffect(() => {
    setEditedName(name);
    setEditedCompany(company);
    setEditedEmail(email);

    const fetchUserData = async () => {
      if (!currentUser) return;
      const db = getFirestore(app);
      const userDocRef = doc(db, 'businesses', currentUser.uid);
      const userDoc = await getDoc(userDocRef);

      if (userDoc.exists()) {
        const userData = userDoc.data();
        setEditedName(userData.name || name);
        setEditedCompany(userData.company || company);
        setEditedEmail(userData.email || email);
        stripePayment.current = userData.stripePayment || false;
      }
    };

    fetchUserData();
  }, [currentUser, name, company, email]);

  const handleInputChange = (field, value) => {
    if (field === 'name') setEditedName(value);
    if (field === 'company') setEditedCompany(value);
    if (field === 'email') setEditedEmail(value);

    setIsChanged(editedName !== name || editedCompany !== company || editedEmail !== email);
  };

  const handleSave = async () => {
    if (!isChanged || !currentUser) return;

    const db = getFirestore(app);
    const userDocRef = doc(db, 'businesses', currentUser.uid);

    try {
      const updates = {};
      if (editedName.trim() !== name) updates.name = editedName.trim();
      if (editedCompany.trim() !== company) updates.company = editedCompany.trim();

      if (Object.keys(updates).length > 0) {
        await updateDoc(userDocRef, updates);
      }

      setIsChanged(false);
      setShowSaveConfirmation(false);
    } catch (error) {
      console.error('Error updating profile:', error);
    }
  };

  const handleLogout = async () => {
    const auth = getAuth(app);
    try {
      await signOut(auth);
      history.push('/login');
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  const handleCancelMembership = async () => {
    if (!currentUser) return;

    const db = getFirestore(app);
    const userDocRef = doc(db, 'businesses', currentUser.uid);
    const cancelledMembershipsRef = doc(db, 'cancelledMemberships', 'businessIDs');

    try {
      await updateDoc(userDocRef, { stripePayment: false });
      await updateDoc(cancelledMembershipsRef, { [currentUser.uid]: true });

      setShowCancelPopup(false);
      history.push('/cancel-membership');
    } catch (error) {
      console.error('Error cancelling membership:', error);
    }
  };

  return (
    <div className="wrapper">
      <div className="account-page">
        <div className="account-info">
          <h1>Account</h1>
          <p>Member Since: {accountCreationYear}</p>

          <div className="input-group">
            <label htmlFor="name">Name:</label>
            <input
              id="name"
              type="text"
              value={editedName}
              onChange={(e) => handleInputChange('name', e.target.value)}
            />
          </div>

          <div className="input-group">
            <label htmlFor="company">Company Name:</label>
            <input
              id="company"
              type="text"
              value={editedCompany}
              onChange={(e) => handleInputChange('company', e.target.value)}
            />
          </div>

          <div className="input-group">
            <label htmlFor="email">Business Email:</label>
            <input
              id="email"
              type="text"
              value={editedEmail}
              readOnly
            />
          </div>

          <button
            className={`save-btn ${isChanged ? 'save-btn-enabled' : 'save-btn-disabled'}`}
            onClick={() => isChanged && setShowSaveConfirmation(true)}
            disabled={!isChanged}
          >
            Save Changes
          </button>

          <button className="logout-btn" onClick={() => setShowLogoutConfirmation(true)}>
            Logout
          </button>
        </div>

        <div className="business-plan">
          <h1>Business Plan</h1>
          <p>Try out Safira Hiring and be able to cancel anytime.</p>
          <p className="plan-price">
            ${stripePayment.current ? "50" : "0"} <span>/ month</span>
          </p>

          <ul>
            <li>Unlimited AI interviews</li>
            <li>24/7 customer support</li>
            <li>Personalized feedback for each candidate</li>
          </ul>

          <p className="not-satisfied-text" onClick={() => setShowCancelPopup(true)}>
            Cancel Membership?
          </p>
        </div>

        {/* Cancel Membership Popup */}
        {showCancelPopup && (
          <div className="cancel-popup">
            <div className="popup-content-account-page">
              <h3>Would you like to cancel? If so, why?</h3>
              <textarea
                value={cancelReason}
                onChange={(e) => setCancelReason(e.target.value)}
                placeholder="Please enter your reason for cancellation..."
              />
              <div className="button-group">
                <button className="close-popup-btn" onClick={() => setShowCancelPopup(false)}>
                  Close
                </button>
                <button className="cancel-membership-btn" onClick={handleCancelMembership}>
                  Cancel Membership
                </button>
              </div>
            </div>
          </div>
        )}

        {/* Save Changes Confirmation Popup */}
        {showSaveConfirmation && (
          <div className="confirmation-popup">
            <div className="confirmation-content">
              <h3>Confirm Save</h3>
              <p>Would you like to save your changes?</p>
              <div className="confirmation-buttons">
                <button onClick={() => setShowSaveConfirmation(false)} className="cancel-button">
                  Cancel
                </button>
                <button onClick={handleSave} className="proceed-button">
                  Save Changes
                </button>
              </div>
            </div>
          </div>
        )}

        {/* Logout Confirmation Popup */}
        {showLogoutConfirmation && (
          <div className="confirmation-popup">
            <div className="confirmation-content">
              <h3>Confirm Logout</h3>
              <p>Are you sure you want to log out?</p>
              <div className="confirmation-buttons">
                <button onClick={() => setShowLogoutConfirmation(false)} className="cancel-button">
                  Cancel
                </button>
                <button onClick={handleLogout} className="proceed-button">
                  Log Out
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AccountPage;
