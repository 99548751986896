import React, { useState, useEffect, useRef } from 'react';
import { getFirestore, doc, getDoc, setDoc } from 'firebase/firestore';
import { useHistory, useLocation } from 'react-router-dom';
import { useAuth } from './authContext';
import { useResume } from './ResumeContext'; 
import './welcomeBusinessInterview.css';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';

const UploadResume = () => {
  const { currentUser } = useAuth();
  const history = useHistory();
  const location = useLocation();
  const jobKey = location.state ? location.state.jobKey : ''; 
  const interviewId = location.state ? location.state.interviewId : null;
  const businessUID = location.state ? location.state.businessUID : null;
  const resumeScreener = location.state ? location.state.resumeScreener : null;

  const [jobTitle, setJobTitle] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const [showNextButton, setShowNextButton] = useState(false);
  const [usePreviousResume, setUsePreviousResume] = useState(false);
  const [hasResume, setHasResume] = useState(false);
  const [isBusiness, setIsBusiness] = useState(false);
  const [isValidInterview, setIsValidInterview] = useState(true);
  const [loading, setLoading] = useState(false);
  const [showInputs, setShowInputs] = useState(false);
  const [fadeOut, setFadeOut] = useState(false);
  const [stage, setStage] = useState(1);
  const [requireResume, setRequireResume] = useState(true);
  const [useResumeCheckbox, setUseResumeCheckbox] = useState(false);
  const { setResumeFile } = useResume();

  const isBusinessRef = useRef(false);
  const uidRef = useRef(currentUser ? currentUser.uid : null);
  const extractedUidRef = useRef(null);
  const jobKeyRef = useRef(null);
  const uniqueIdRef = useRef(null);
  const beenPressed = useRef(false);

    const checkInterviewValidity = async (uid, jobKey, uniqueId) => {
      const db = getFirestore();
      const jobRef = doc(db, 'businesses', uid, jobKey, 'interviewees');
      const jobSnapshot = await getDoc(jobRef);
  
      if (jobSnapshot.exists() && jobSnapshot.data()[uniqueId]) {
        const intervieweeData = jobSnapshot.data()[uniqueId];
        if (intervieweeData.scores) {
          console.log("Interviewee exists but has scores, invalid interview.");
          return false;
        }
        if (intervieweeData.interviewStarted === true) {
          console.log("Interviewee exists but has already started interview, invalid interview.");
          return false;
        }
        console.log("Interviewee exists");
        return true;
      }
      console.log("Interviewee does not exist");
      return false;
    };

  useEffect(() => {
    const validateInterview = async () => {
      const pathParts = location.pathname.split('/');
      const whiteLabel = pathParts[pathParts.length - 1] === "ai-interview";

      if (pathParts.length === 5 || (pathParts.length === 6 && whiteLabel) || (pathParts.length === 6 && resumeScreener) || (pathParts.length === 7 && whiteLabel && whiteLabel)) {
        const extractedUid = pathParts[2];
        const jobKey = pathParts[3];
        const uniqueId = pathParts[4];

        extractedUidRef.current = extractedUid;
        jobKeyRef.current = jobKey;
        uniqueIdRef.current = uniqueId;
        setIsBusiness(true);
        isBusinessRef.current = true;

        const db = getFirestore();

        // Fetch `requireResume` and `jobTitle` from Firestore
        const jobDesDocRef = doc(db, 'businesses', extractedUid, jobKey, 'jobDescription');
        const jobDesSnapshot = await getDoc(jobDesDocRef);

        if (jobDesSnapshot.exists()) {
          const jobData = jobDesSnapshot.data();
          setRequireResume(jobData.requireResume ?? true);
          setJobTitle(jobData.jobTitle || "Untitled Job");
          console.log("Require Resume:", jobData.requireResume);
          console.log("Job Title:", jobData.jobTitle);
        } else {
          console.log("Job description document not found!");
          setJobTitle("Untitled Job");
        }

        if (extractedUid && jobKey && uniqueId) {
          const isValid = await checkInterviewValidity(extractedUid, jobKey, uniqueId);
          setIsValidInterview(isValid);
        } else {
          setIsValidInterview(false);
        }
      } else {
        setIsValidInterview(true);
      }
      setLoading(false);
    };

    validateInterview();
  }, [location.pathname]); 

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
    setResumeFile(file);
    console.log("Selected file:", file);
    setShowNextButton(true);

    // Reset the file input value to allow re-selection of the same file
    event.target.value = '';
  };

  const handleFileDelete = () => {
    setSelectedFile(null);
    setShowNextButton(false);
    setUseResumeCheckbox(false); // Reset the checkbox state
  };

  const handleNext = async () => {
    if (!beenPressed.current) {
      console.log("Button has been pressed");
      beenPressed.current = true;
      setLoading(true);

      const formData = new FormData();
      const formattedJobTitle = jobTitle.replace(/\s+/g, '-');
      let downloadURL = ''

      if (selectedFile) {
        formData.append('resume', selectedFile);

        // Upload the file to Firebase Storage
        const storage = getStorage();
        const storageRef = ref(storage, `resumes/${extractedUidRef.current}/${formattedJobTitle}/${uniqueIdRef.current}`);
        try {
          const snapshot = await uploadBytes(storageRef, selectedFile);
          console.log('Uploaded a blob or file!');

          // Get the download URL
          downloadURL = await getDownloadURL(snapshot.ref);
          console.log('Download URL:', downloadURL);

          // Call the updated function with the download URL
          await storeUserEmailResume(downloadURL);
        } catch (error) {
          console.error('Error uploading file to Firebase Storage:', error);
          setLoading(false);
          beenPressed.current = false;
          return;
        }
      } else{
        const response = await fetch('/emptypdfsafira.pdf');
        const blob = await response.blob();
        formData.append('resume', blob, 'emptypdfsafira.pdf');
      }

      try {
        let urlPath = isBusinessRef.current
          ? `/uploadResume/${extractedUidRef.current}/${jobKeyRef.current}/${uidRef.current}`
          : `/uploadResume/${uidRef.current}/${jobTitle}`;

        const response = await fetch(`https://fractalflaskapp-5c91849aadae.herokuapp.com${urlPath}`, {
          method: 'POST',
          body: formData
        });
        console.log('Status code:', response.status);

        const responseData = await response.json();
        console.log('Response:', responseData);

        if (response.ok) {
          // Extract topics_list from the response
          const topicsList = responseData.topics_list;
          console.log('Generated Topics List:', topicsList);

          const pathParts = location.pathname.split('/');
          const whiteLabel = pathParts[pathParts.length - 1] === "ai-interview";
          console.log("whiteLabel: ", whiteLabel);
          let redirectPath = isBusinessRef.current
            ? `/getready/${extractedUidRef.current}/${jobKeyRef.current}/${uniqueIdRef.current}${whiteLabel ? '/ai-interview' : ''}`
            : '/getready';
          if(resumeScreener){
            redirectPath = `/application-questions/${extractedUidRef.current}/${jobKeyRef.current}/${uniqueIdRef.current}/016310${whiteLabel ? '/ai-interview' : ''}`;
          }

          setFadeOut(true); // Trigger fade-out animation

          setTimeout(() => {
            history.push({
              pathname: redirectPath,
              state: { 
                jobKey: jobKeyRef.current, 
                isBusiness: isBusiness,
                topicsList: topicsList
              }
            });
          }, 1000); // Delay to allow fade-out animation to complete
        } else {
          console.error('Error uploading resume');
          beenPressed.current = false; // Reset beenPressed
        }
      } catch (error) {
        console.error('Error uploading resume:', error);
        beenPressed.current = false; // Reset beenPressed
      }
    }
  };

  const storeUserEmailResume = async (resumeURL) => {    
    console.log("Storing user email and resume URL...");
    const db = getFirestore();
    
    // Store the resume URL in the 'interviewees' collection
    if (interviewId && extractedUidRef.current && jobKeyRef.current && uniqueIdRef.current) {
      const intervieweeDocRef = doc(
        db,
        `businesses/${extractedUidRef.current}/${jobKeyRef.current}/interviewees`
      );
      
      await setDoc(
        intervieweeDocRef,
        {
          [uniqueIdRef.current]: {
            resumeURL: resumeURL,
          },
        },
        { merge: true } // Merge with existing data
      );
      console.log('Resume URL stored successfully.');
    } else {
      console.error('Failed to store resume URL: Missing required identifiers.');
    }
  };  

  useEffect(() => {
    console.log("resume", resumeScreener)
    const timer = setTimeout(() => {
      setShowInputs(true);
    }, resumeScreener 
      ? "To finish your application, I'll need to look at your resume.".split(" ").length * 0.2 
      : "To ask the most detailed questions, I'll need to look at your resume.".split(" ").length * 0.2
    ); // Adjust the delay to match the animation delay

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const handleKeyPress = (event) => {
      // if (event.key === 'Enter') {
      //   setFadeOut(true); 
      //   setTimeout(() => {
      //     handleNext();
      //   }, 1000); 
      // } else
      // if (event.key === ' ') {
      //   setStage(stage + 1); // Move to the next stage when spacebar is pressed
      // }
    };

    window.addEventListener('keydown', handleKeyPress);
    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [stage]);

  const handleCheckboxChange = (event) => {
    setUseResumeCheckbox(event.target.checked);
    setShowNextButton(event.target.checked); // Show "Next" button if checked
  };

  if (loading && beenPressed.current) {
    return <LoadingPopup />;
  }

  if (loading) {
    return <div>Loading...</div>;
  }

  console.log("checking", requireResume, selectedFile); 

  return (
    <div className="welcome-container">
      {isValidInterview ? (
        stage === 0 ? (
          <>
            <h1 className={`welcome-text upload-resume ${fadeOut ? 'fade-out' : ''}`}>
              {"Welcome to your interview. I am your AI Interviewer.".split(" ").map((word, index) => (
                <span key={index} className="fade-in-char" style={{ animationDelay: `${index * 0.3}s` }}>
                  {word}&nbsp;
                </span>
              ))}
            </h1>
            <h2 className={`upload-resume-subtitle ${fadeOut ? 'fade-out' : ''} white-text`}>
              {"Press spacebar to continue".split(" ").map((word, index) => (
                <span key={index} className="fade-in-char" style={{ animationDelay: `${index * 0.3 + 3}s` }}>
                  {word}&nbsp;
                </span>
              ))}
            </h2>
          </>
        ) : stage === 1 ? (
          <>
            <h1 className={`upload-resume-title`}>
              {(resumeScreener 
                    ? "To finish your application, I'll need to look at your resume."
                    : "To ask the most detailed questions, I'll need to look at your resume.").split(" ").map((word, wordIndex) => (
                <span key={wordIndex} className="fade-in-char" style={{ animationDelay: `${wordIndex * 0.1}s` }}>
                    {word}&nbsp;
                </span>
                ))}
            </h1>
            {showInputs && (
              <div className={`resume-inputs-container ${fadeOut ? 'fade-out' : ''}`}>
                {!requireResume && !selectedFile && (
                  <div>
                    <input
                      type="checkbox"
                      id="use-resume-checkbox"
                      checked={useResumeCheckbox}
                      onChange={handleCheckboxChange}
                    />
                    <label htmlFor="use-resume-checkbox" className="upload-resume-checkbox"> Continue without resume upload </label>
                  </div>
                )}
                <br />
                <div style={{ visibility: useResumeCheckbox ? 'hidden' : 'visible' }}>
                  <input
                    type="file"
                    accept=".pdf"
                    onChange={handleFileChange}
                    style={{ display: 'none' }}
                    id="resume-upload"
                  />
                  <label htmlFor="resume-upload" className="interview-next-button upload-resume">Upload Here</label>
                </div>
                {selectedFile && (
                  <div style={{ marginTop: '20px' }}>
                    <span>{selectedFile.name}</span>
                    <button onClick={handleFileDelete} style={{ marginLeft: '10px' }}>X</button>
                  </div>
                )}
                {showNextButton && (
                  <button
                    type="button"
                    className="uploadresume-next-button"
                    onClick={handleNext}
                  >
                    Next
                  </button>
                )}
              </div>
            )}
          </>
        ) : null
      ) : (
        <h1 className={`upload-resume-title ${fadeOut ? 'fade-out' : ''}`}>
          {"Interview has expired".split(" ").map((word, index) => (
            <span key={index} className="fade-in-char" style={{ animationDelay: `${index * 0.3}s` }}>
              {word}&nbsp;
            </span>
          ))}
        </h1>
      )}
    </div>
  );
};

const LoadingPopup = () => (
  <div className="loading-popup">
    <div className="loading-icon"></div>
    <p>Loading...</p>
  </div>
);

export default UploadResume;