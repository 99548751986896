import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useResume } from './ResumeContext';
import { useAuth } from './authContext';
import { getFirestore, doc, setDoc } from 'firebase/firestore';
import './ApplicationQuestions.css';
import './welcomeBusinessInterview.css';

const ApplicationQuestions = () => {
    const { currentUser } = useAuth();
    const history = useHistory();
    const location = useLocation();
    const jobKey = location.state ? location.state.jobKey : ''; 
    const topicsList = location.state ? location.state.topicsList : []; 
    const pathParts = location.pathname.split('/');
    const whiteLabel = pathParts[pathParts.length - 1] === "ai-interview";
    const businessUID = pathParts[2];
    const uid = pathParts[4];
    const { resumeFile } = useResume(); 

    const [formData, setFormData] = useState({});

    const handleChange = (e, topic) => {
        setFormData({ ...formData, [topic]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
    
        // Validate that all questions are answered
        const unansweredQuestions = topicsList
            .slice(0, -1)
            .filter((topic) => topic.trim().endsWith('?'))
            .some((topic) => !formData[topic] || formData[topic].trim() === '');
    
        if (unansweredQuestions) {
            alert('Please answer all questions before submitting.');
            return;
        }
    
        // Format the question-answers as a single string
        const questionAnswers = topicsList
            .slice(0, -1)
            .filter((topic) => topic.trim().endsWith('?'))
            .map((topic) => `Q: ${topic}\nA: ${formData[topic]}`)
            .join('\n\n'); // Separate questions and answers with two newlines

        try {
            const db = getFirestore(); 
            const intervieweesDocRef = doc(db, 'businesses', businessUID, jobKey, 'interviewees');
        
            await setDoc(
                intervieweesDocRef,
                {
                [uid]: {
                    questionAnswers: questionAnswers
                }
                },
                { merge: true }
            );
        
            console.log('questionAnswers saved to Firestore:', questionAnswers);
            } catch (error) {
            console.error('Error saving questionAnswers to Firestore:', error);
            alert('Could not save your application data. Please try again.');
            return; // Stop here if Firestore fails
        }
    
        // Prepare the FormData for submission
        const formDataToSubmit = new FormData();
        formDataToSubmit.append('question_answers', questionAnswers);
    
        try {

            if (!resumeFile) {
                console.warn('No resume file in context.');
            } else {
                console.log("resume exists");
                formDataToSubmit.append('resume', resumeFile, 'resume.pdf');
            }
    
            fetch(
                `https://fractalflaskapp-5c91849aadae.herokuapp.com/uploadResume-resumescreener/${businessUID}/${jobKey}/${uid}`,
                {
                  method: 'POST',
                  body: formDataToSubmit,
                }
              )
                .catch((error) => console.error('Error submitting form:', error));
          
              // Immediately redirect (no waiting):
              history.push('/interviewdone/016310');
          
        } catch (error) {
            console.error('Error handling submission:', error);
            alert('An error occurred while submitting the form. Please try again later.');
        }
    };
    

    return (
        <div className="welcome-container-appq">
            <div className="form-container-appq">
                <h1>Application Questions</h1>
                <form onSubmit={handleSubmit}>
                    {topicsList.slice(0,-1).filter((topic) => topic.trim().endsWith('?')).map((topic, index) => (
                        <div className="form-group-appq" key={index}>
                            <label htmlFor={`question-${index}`} className="form-label-appq">
                                {topic}
                            </label>
                            <textarea
                                id={`question-${index}`}
                                className="form-textarea-appq"
                                value={formData[topic] || ''}
                                onChange={(e) => handleChange(e, topic)}
                                placeholder={`Enter your response`}
                            />
                        </div>
                    ))}
                    <button type="submit" className="interview-next-button">
                        Submit
                    </button>
                </form>
            </div>
        </div>
    );
};

export default ApplicationQuestions;
