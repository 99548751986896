import React, { useState, useEffect } from 'react';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import './PreviewPopup.css';
import { useAuth } from './authContext';
import { app } from './index';
import { getFirestore, doc, setDoc, updateDoc, arrayUnion, getDoc } from 'firebase/firestore/lite';

const PreviewPopup = ({ isOpen, onClose, jobTitle, jobKey, timeLimit, jobDescription, scoringCriteria, onSubmit, customQuestions: initialCustomQuestions, aiQuestions: initialAiQuestions, requireCamera, isEditing, requireResume, label=null }) => {
  const [customQuestions, setCustomQuestions] = useState([]);
  const [aiQuestions, setAiQuestions] = useState([]);
  const { currentUser } = useAuth();
  const [introMessage, setIntroMessage] = useState('');
  const [companyName, setCompanyName] = useState('');

  useEffect(() => {
    console.log(requireCamera);
    console.log(requireResume);
    setCustomQuestions(initialCustomQuestions);
    setAiQuestions(requireResume ? initialAiQuestions : []);
  }, [initialCustomQuestions, initialAiQuestions, requireResume]);
  useEffect(() => {
    // Fetch the company name from Firebase
    const fetchCompanyName = async () => {
      if (!currentUser) return;

      const db = getFirestore(app);
      const userDocRef = doc(db, 'businesses', currentUser.uid);

      try {
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists()) {
          const data = userDoc.data();
          setCompanyName(data.company || 'your company'); // Use a fallback if `company` doesn't exist
        }
      } catch (error) {
        console.error("Error fetching company name: ", error);
      }
    };

    fetchCompanyName();
  }, [currentUser]);

  function generateRandomKey(length = 16) {
    const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let key = '';
    for (let i = 0; i < length; i++) {
        key += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    return key;
}

  const handleAddCustomQuestion = () => {
    if (customQuestions.length < 5) {
      setCustomQuestions([...customQuestions, '']);
    }
  };

  const handleCustomQuestionChange = (index, value) => {
    const updatedQuestions = [...customQuestions];
    updatedQuestions[index] = value;
    setCustomQuestions(updatedQuestions);
  };

  const handleRemoveCustomQuestion = (index) => {
    const updatedQuestions = customQuestions.filter((_, i) => i !== index);
    setCustomQuestions(updatedQuestions);
  };

  const handleRemoveAiQuestion = (index) => {
    const updatedQuestions = aiQuestions.filter((_, i) => i !== index);
    setAiQuestions(updatedQuestions);
  };

  const handleAddAiQuestion = () => {
    if (!requireResume) {
      alert("Resume must be required for AI capabilities.");
      return;
    }
    if (aiQuestions.length < 5) {
      setAiQuestions([...aiQuestions, 'AI Question']);
    }
  };

  const moveQuestion = (dragIndex, hoverIndex, questionType) => {
    const questions = questionType === 'custom' ? customQuestions : aiQuestions;
    const dragQuestion = questions[dragIndex];
    const updatedQuestions = [...questions];
    updatedQuestions.splice(dragIndex, 1);
    updatedQuestions.splice(hoverIndex, 0, dragQuestion);

    if (questionType === 'custom') {
      setCustomQuestions(updatedQuestions);
    } else {
      setAiQuestions(updatedQuestions);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check for blank questions
    if (customQuestions.some(q => q.trim() === '') || aiQuestions.some(q => q.trim() === '')) {
      alert("Please fill in all questions or remove blank ones before submitting.");
      return;
    }

    // Ensure at least one custom question if resume is not required
    if (!requireResume && customQuestions.length === 0) {
      if (label !== 'resume screener'){
        alert("There are no questions in the interview. Please add at least one custom question.");
      }
      return;
    }

    const scoringCriteriaMap = {};
    scoringCriteria.forEach((criteria) => {
      scoringCriteriaMap[criteria.skill.toLowerCase()] = criteria.weighting;
    });

    if (!currentUser) return;

    const db = getFirestore(app);
    const randomJobKey = generateRandomKey();
    const generatedLink = `https://safirahiring.com/welcomeinterview/${randomJobKey}/${currentUser.uid}${label === 'resume screener' ? '/016310' : ''}`;

    const createdAt = new Date().toLocaleDateString('en-US', { month: 'long', day: '2-digit', year: 'numeric' });

    try {
      if (isEditing) {
        // Update the existing document without overwriting
        console.log("jobkeypreview:", jobKey);
        const jobDesDocRef = doc(db, 'businesses', currentUser.uid, jobKey, 'jobDescription');
        await updateDoc(jobDesDocRef, {
          jobTitle,
          jobDescription,
          customQuestions,
          timeLimit,
          requireCamera,
          requireResume,
          aiQuestions: aiQuestions.length, // Store the count instead of the list
          personalityTraits: scoringCriteriaMap,
          introMessage
        });
      } else {
        // Create a new document for the job with an empty 'interviewees' field
        const jobDocRef = doc(db, 'businesses', currentUser.uid, randomJobKey, 'interviewees');
        await setDoc(jobDocRef, {});

        const jobDesDocRef = doc(db, 'businesses', currentUser.uid, randomJobKey, 'jobDescription');
        await setDoc(jobDesDocRef, {
          generatedLink,
          timeLimit,
          requireCamera,
          requireResume,
          jobDescription,
          customQuestions,
          aiQuestions: aiQuestions.length, // Store the count instead of the list
          personalityTraits: scoringCriteriaMap,
          createdAt,
          jobTitle,
          introMessage
        });

        const userDocRef = doc(db, 'businesses', currentUser.uid);
        if(label !== 'resume screener'){
          await updateDoc(userDocRef, {
            currentJobs: arrayUnion(randomJobKey)
          });
        }else{
          await updateDoc(userDocRef, {
            currentResumeJobs: arrayUnion(randomJobKey)
          });
        }
      }

      window.location.reload(); // Reload the whole page after adding the job
    } catch (error) {
      console.error("Error adding job: ", error);
    }

    onClose();
  };

  const calculateEstimatedLength = () => {
    return Math.round(customQuestions.length * 1.5 + aiQuestions.length * 2.5);
  };

  useEffect(() => {
    calculateEstimatedLength();
  }, [customQuestions, aiQuestions]);

  if (!isOpen) return null;

  return (
    <DndProvider backend={HTML5Backend}>
      <div className="preview-popup-overlay">
        <div className="preview-popup-content">
          <div className="popup-header">
          {label !== 'resume screener' && (
            <>
            <h2 className="popup-heading">Interview Preview</h2>
            <span className="estimated-length">Estimated length: {calculateEstimatedLength()} minutes</span>
            </>
          )}
          {label === 'resume screener' && (
            <>
            <h2 className="popup-heading">Application Preview</h2>
            </>
          )}
          </div>
          <hr />

          <div className="questions-section">
            <h3 className="popup-subheading">Introduction Message (optional)</h3>
            {label !== 'resume screener' && (
              <>
              <p className="popup-description">This text will be shown when the candidate clicks the interview link.</p>
              </>
            )}
            {label === 'resume screener' && (
              <>
              <p className="popup-description">This text will be shown when the candidate clicks the application link.</p>
              </>
            )}
            <input
              type="text"
              className="intro-input"
              placeholder={`Welcome to your ${jobTitle} ${label==='resume screener' ? 'application' : 'interview'} at ${companyName}`}
              value={introMessage}
              maxLength={100}

              onChange={(e) => setIntroMessage(e.target.value)}
            />
          </div>

          <hr />

          <div className="questions-section">
            <h3 className="popup-subheading">Custom Questions</h3>
            <p className="popup-description">
              The {label === 'resume screener' ? 'application' : 'interviewer'} will ask the candidate these questions verbatim. 
            </p>
            <div className="questions-list">
              {customQuestions.map((question, index) => (
                <QuestionItem
                  key={index}
                  index={index}
                  question={question}
                  onChange={(value) => handleCustomQuestionChange(index, value)}
                  onRemove={() => handleRemoveCustomQuestion(index)}
                  moveQuestion={(dragIndex, hoverIndex) => moveQuestion(dragIndex, hoverIndex, 'custom')}
                  isCustom={true}
                  isDeletable={true}
                />
              ))}
            </div>
            {customQuestions.length < 5 && (
              <button className="add-question-button" onClick={handleAddCustomQuestion}>
                + Add Custom Question
              </button>
            )}
          </div>

          <hr />

          <div className="questions-section">
            <h3 className="popup-subheading">AI Questions</h3>
            {label !== 'resume screener' && (
              <>
                <p className="popup-description">
                  These questions will be generated in real-time based on the Job Description and their Resume.
                </p>              
              </>
            )}
            {label === 'resume screener' && (
              <>
                <p className="popup-description">
                  These questions will be generated based on the Job Description and their resume using AI.
                </p>              
              </>
            )}
            <div className="questions-list">
              {aiQuestions.map((question, index) => (
                <QuestionItem
                  key={index}
                  index={index}
                  question={question}
                  onChange={() => {}}
                  onRemove={() => handleRemoveAiQuestion(index)}
                  moveQuestion={(dragIndex, hoverIndex) => moveQuestion(dragIndex, hoverIndex, 'ai')}
                  isCustom={false}
                  isDeletable={true}
                />
              ))}
            </div>
            {aiQuestions.length < 5 && (
              <button className="add-question-button" onClick={handleAddAiQuestion}>
                + Add AI Question
              </button>
            )}
          </div>

          <div className="popup-buttons">
            <button type="button" className="close-button" onClick={onClose}>
              Back
            </button>
            <button type="button" className="create-button" onClick={handleSubmit}>
              {isEditing ? 'Update Position' : 'Create'}
            </button>
          </div>
        </div>
      </div>
    </DndProvider>
  );
};

const QuestionItem = ({ question, index, onChange, onRemove, moveQuestion, isCustom, isDeletable }) => {
  const ref = React.useRef(null);
  const [, drop] = useDrop({
    accept: 'QUESTION',
    hover(item) {
      if (item.index !== index) {
        moveQuestion(item.index, index);
        item.index = index;
      }
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: 'QUESTION',
    item: { index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drag(drop(ref));

  return (
    <div
      ref={ref}
      className="question-item"
      style={{ opacity: isDragging ? 0.5 : 1 }}
    >
      <input
        type="text"
        value={question}
        onChange={(e) => onChange(e.target.value)}
        className="question-input"
        disabled={!isCustom}
      />
      {isDeletable && (
        <button
          type="button"
          className="remove-question-button"
          onClick={onRemove}
        >
          🗑️
        </button>
      )}
    </div>
  );
};

export default PreviewPopup;
