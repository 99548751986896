import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import './welcomeBusinessInterview.css';
import './App.css';

const WelcomeBusinessInterview = () => {
  const [welcomeText, setWelcomeText] = useState("Welcome");
  const [termsAgreed, setTermsAgreed] = useState(false); // State for terms agreement
  const [showTerms, setShowTerms] = useState(false); // State to show terms checkbox after animation
  const history = useHistory();
  const location = useLocation();
  const pathParts = location.pathname.split('/');
  const whiteLabel = pathParts[pathParts.length - 1] === "ai-interview";
  const resumeScreener = pathParts[4] === "016310";
  const [firstPart, jobKey, businessUID] = pathParts.slice(1, 4);

  const fadeInDuration = 0.2; // Duration for each character to fade in (in seconds)
  const totalDuration = welcomeText.length * fadeInDuration * 300 ; // Total duration in milliseconds used for agree terms

  useEffect(() => {
    const fetchData = async () => {
      const db = getFirestore();
      let company = "your company";
      let jobTitle = "Untitled Job";
      let introMessage = "";

      // Fetch the company name from the businesses document
      const businessRef = doc(db, 'businesses', businessUID);
      const businessSnap = await getDoc(businessRef);
      if (businessSnap.exists()) {
        company = businessSnap.data().company || company;
      } else {
        console.log("Business document not found!");
      }

      // Fetch the job title from the jobDescription document
      const formattedJobKey = jobKey.replace(/-/g, ' ');
      console.log(formattedJobKey);
      const jobDescriptionRef = doc(db, 'businesses', businessUID, formattedJobKey, 'jobDescription');
      const jobDescriptionSnap = await getDoc(jobDescriptionRef);
      if (jobDescriptionSnap.exists()) {
        jobTitle = jobDescriptionSnap.data().jobTitle || jobTitle;
        introMessage = jobDescriptionSnap.data().introMessage || (
          resumeScreener
            ? `Welcome to your ${jobTitle} application at ${company}.`
            : `Welcome to your ${jobTitle} interview at ${company}.`
        );
      } else {
        console.log("Job description document not found!");
        introMessage = "This interview is invalid. Please contact support";
      }

      setWelcomeText(introMessage);
    };

    fetchData();

    console.log("WelcomeInterview component mounted");

    if (whiteLabel) {
      const navbar = document.querySelector('.menu-bar');
      if (navbar) {
        navbar.classList.add('hide');
      }
    }

    const timer = setTimeout(() => {
      setShowTerms(true); // Show the terms checkbox after animation
    }, totalDuration); // Adjust fade-in timing to be slightly earlier

    return () => {
      clearTimeout(timer);
      console.log("WelcomeInterview component unmounted");
    };
  }, [jobKey, businessUID, whiteLabel, totalDuration]);

  const handleNext = () => {
    let newPath = `/name-and-email/${jobKey}/${businessUID}`;
    
    if (resumeScreener) {
      newPath += '/016310';
    }
  
    if (whiteLabel) {
      newPath += resumeScreener ? '/ai-interview' : '/ai-interview/016310';
    }
  
    console.log(`Navigating to ${newPath}`);
    history.push(newPath);
  };

  return (
    <div>
      <div className="welcome-container">
        <h1 className="welcome-text">
          {welcomeText.split(" ").map((char, index) => (
            <span key={index} className="fade-in-char" style={{ animationDelay: `${index * fadeInDuration}s` }}>
              {char}&nbsp;
            </span>
          ))}
        </h1>
        {showTerms && (
          <div className="terms-container">
            <input 
              type="checkbox" 
              id="terms" 
              checked={termsAgreed} 
              onChange={() => setTermsAgreed(!termsAgreed)}
              style={{ marginRight: '5px', transform: 'translateY(3px)' }}
            />
            <label htmlFor="terms" style={{ color: 'white', fontSize: '13px', padding: '5px' }}>
              I agree to the <a href="/Safira_Terms.pdf" target="_blank" style={{ textDecoration: 'underline', color: 'blue', cursor: 'pointer' }}>Terms and Conditions</a>
            </label>
          </div>
        )}
        <div className="button-container">
          <button 
            className="interview-next-button" 
            onClick={handleNext}
            disabled={!termsAgreed} // Disable button if terms are not agreed
            style={{ backgroundColor: termsAgreed ? '#dd5050' : 'gray', cursor: termsAgreed ? 'pointer' : 'not-allowed' }}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default WelcomeBusinessInterview;
