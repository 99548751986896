import React from 'react';
import './ConfirmDeletePopup.css'; // Ensure you have the corresponding CSS file

const ConfirmDeletePopup = ({ isOpen, jobKey, onConfirm, onCancel }) => {
  if (!isOpen) return null;

  return (
    <div className="popup-overlay">
      <div className="popup-content">
        <p>Are you sure you want to delete {jobKey}?</p>
        <button className="popup-button" onClick={onConfirm}>Yes</button>
        <button className="popup-button" onClick={onCancel}>No</button>
      </div>
    </div>
  );
};

export default ConfirmDeletePopup;