import React, { useState, useEffect, useRef } from 'react';
import { useAuth } from './authContext';
import './candidateInfo.css';

const CandidateInfo = ({ selectedCandidate, videos, overallScore, resumeURL, jobKey, label2 = null }) => {
  const [isFeedbackVisible, setIsFeedbackVisible] = useState(true);
  const [selectedTrait, setSelectedTrait] = useState(null);
  const [videoUrl, setVideoUrl] = useState(null);
  const [isVideoPopupVisible, setIsVideoPopupVisible] = useState(false);
  const { currentUser } = useAuth();

  const candidateInfoRef = useRef(null);
  const [isVisible, setIsVisible] = useState(true);

  const toggleFeedbackVisibility = () => {
    setIsFeedbackVisible(!isFeedbackVisible);
  };

  const handleTraitClick = (trait) => {
    setSelectedTrait(selectedTrait === trait ? null : trait);
  };

  // This handler uses the human bubble index to look up a video.
  const handlePlayClickCandidate = (candidateIndex) => {
    if (label2 !== 'resume screener' && videos && videos[candidateIndex]) {
      setVideoUrl(videos[candidateIndex]);
      setIsVideoPopupVisible(true);
    }
  };

  const closeVideoPopup = () => {
    setIsVideoPopupVisible(false);
    setVideoUrl(null);
  };

  const handleClickOutside = (event) => {
    if (isVideoPopupVisible) {
      closeVideoPopup();
      return;
    }
    if (candidateInfoRef.current && !candidateInfoRef.current.contains(event.target)) {
      setIsVisible(false);
    }
  };

  useEffect(() => {
    setIsVisible(true);
  }, [selectedCandidate]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isVideoPopupVisible]);

  // Scores and traits
  const scores = (selectedCandidate && selectedCandidate.scores) || {};
  const traits = scores ? Object.keys(scores) : [];

  const calculateOverallScore = () => {
    const scoreValues = Object.values(scores);
    const totalScore = scoreValues.reduce((total, score) => total + score, 0);
    return Math.round(totalScore / scoreValues.length) || 0;
  };

  const overall = calculateOverallScore();

  // Copy link
  const { currentUser: user } = useAuth();
  const copyLink = () => {
    if (selectedCandidate && selectedCandidate.uid && user) {
      let link = `https://safirahiring.com/candidate-result/${user.uid}/${selectedCandidate.uid}/${jobKey}`;
      if (label2 === 'resume screener') {
        link += '/016310';
      }
      navigator.clipboard
        .writeText(link)
        .then(() => console.log('Link copied to clipboard!'))
        .catch((err) => console.error('Failed to copy link: ', err));
    }
  };

  // Show resume
  const showResumes = () => {
    if (selectedCandidate && selectedCandidate.resumeURL) {
      window.open(selectedCandidate.resumeURL, '_blank');
    } else {
      console.log('No resume URL available');
    }
  };

  if (!isVisible || !selectedCandidate) return null;

  const shouldShowCandidateInfo =
    selectedCandidate && scores && Object.keys(scores).length > 0;

  const traitColors = [
    '#D1DCF3',
    '#FFD07A',
    '#CEED89',
    '#FDCFE9',
    '#FF8440',
    '#94FFFF',
  ];

  function parseQA(questionAnswers) {
    const lines = questionAnswers.split('\n').filter((line) => line.trim() !== '');
    return lines.map((line) => {
      if (line.startsWith('Q:')) {
        return { role: 'assistant', content: line };
      } else if (line.startsWith('A:')) {
        return { role: 'human', content: line };
      } else {
        return { role: 'human', content: line };
      }
    });
  }

  return (
    <aside
      className={`candidate-info ${shouldShowCandidateInfo ? 'show' : ''}`}
      ref={candidateInfoRef}
    >
      <div className="candidate-info-scroll">
        <div className="candidate-info-header">
          <h2 style={{ fontSize: '1.2em' }}>
            {selectedCandidate ? selectedCandidate.name : 'Candidate Name'}
          </h2>
          <div>
            <button
              className="copy-link-button-candidate"
              onClick={copyLink}
              style={{ position: 'absolute', top: '10px', right: '10px' }}
            >
              Copy Link
            </button>
            <button
              className="copy-link-button-candidate"
              onClick={showResumes}
              style={{ position: 'absolute', top: '60px', right: '10px' }}
            >
              Show Resume
            </button>
          </div>
          <div className="overall-score">
            <div
              className="score-circle overall-circle"
              style={{
                backgroundColor: '#FFE4C7',
                color: '#000',
                fontWeight: 'bold',
                width: '90px',
                height: '90px',
                fontSize: '1.5em',
              }}
            >
              {overallScore}
            </div>
            <p>Overall</p>
            <div className="horizontal-circles">
              {traits.slice(0, 6).map((trait, idx) => (
                <div
                  className="circle-with-word"
                  key={idx}
                  onClick={() => handleTraitClick(trait)}
                  style={{ cursor: 'pointer' }}
                >
                  <div
                    className="score-circle small-circle"
                    style={{
                      backgroundColor: traitColors[idx % traitColors.length],
                      fontSize: '0.6em',
                      border:
                        selectedTrait === trait ? '2px solid #FF6B19' : 'none',
                    }}
                  >
                    {scores[trait] || '00'}
                  </div>
                  <p className="trait-word" style={{ fontSize: '0.7em' }}>
                    {trait.charAt(0).toUpperCase() + trait.slice(1)}
                  </p>
                </div>
              ))}
            </div>
            {selectedCandidate.skills && selectedCandidate.skills.length > 0 && (
              <div className="skills-container">
                {selectedCandidate.skills.map((skill, i) => (
                  <span key={i} className="skill-box">
                    {skill}
                  </span>
                ))}
              </div>
            )}
          </div>
        </div>
        <hr />
        <div className="candidate-info-feedback">
          <h3 style={{ fontSize: '1.5em' }}>Feedback</h3>
          <div className="feedback-content">
            {selectedTrait ? (
              <p>
                <strong>
                  {selectedTrait.charAt(0).toUpperCase() + selectedTrait.slice(1)}:
                </strong>{' '}
                {selectedCandidate.feedback
                  ? selectedCandidate.feedback[selectedTrait]
                  : 'No feedback available.'}
              </p>
            ) : (
              <p style={{ color: 'gray', textAlign: 'center' }}>
                Click a trait to see feedback
              </p>
            )}
          </div>
        </div>
        <hr />
        {label2 === 'resume screener' && selectedCandidate.questionAnswers && (
          <div className="candidate-info-transcript">
            <h3 style={{ fontSize: '1.5em' }}>
              Application Questions &amp; Answers
            </h3>
            {parseQA(selectedCandidate.questionAnswers).map((item, idx) => (
              <div
                key={idx}
                className={`bubbletemplate ${item.role === 'assistant' ? 'ai' : 'human'}`}
              >
                {item.content}
              </div>
            ))}
          </div>
        )}
        {label2 !== 'resume screener' && (
          <div className="candidate-info-transcript">
            <h3 style={{ fontSize: '1.5em' }}>Transcript</h3>
            {selectedCandidate.transcript ? (
              (() => {
                // Remove the first transcript item
                const rawTranscript = selectedCandidate.transcript.slice(1);
                let videoIndex = 0;
                const processedTranscript = [];

                // Process each transcript item.
                for (let i = 0; i < rawTranscript.length; i++) {
                  const item = rawTranscript[i];
                  if (item.role === 'assistant') {
                    // Push the assistant bubble.
                    processedTranscript.push(item);
                    // If the next bubble is also an assistant or this is the last one, insert an empty human bubble.
                    if (
                      videoIndex < videos.length &&
                      (i === rawTranscript.length - 1 || rawTranscript[i + 1].role === 'assistant')
                    ) {
                      processedTranscript.push({ role: 'user', content: '', _videoIndex: videoIndex });
                      videoIndex++;
                    }
                  } else {
                    // For a human bubble, attach the next available video index.
                    processedTranscript.push({
                      ...item,
                      _videoIndex: videoIndex < videos.length ? videoIndex : null,
                    });
                    if (videoIndex < videos.length) {
                      videoIndex++;
                    }
                  }
                }
                // If there are still unused videos, add empty human bubbles.
                while (videoIndex < videos.length) {
                  processedTranscript.push({ role: 'user', content: '', _videoIndex: videoIndex });
                  videoIndex++;
                }

                // Render the processed transcript.
                return processedTranscript.map((bubbleItem, idx) => {
                  if (bubbleItem.role === 'assistant') {
                    return (
                      <div className="bubbletemplate ai" key={idx}>
                        {bubbleItem.content}
                      </div>
                    );
                  } else {
                    return (
                      <div className="bubbletemplate human" key={idx}>
                        {bubbleItem._videoIndex !== null && (
                          <button
                            className="play-button-speech"
                            onClick={() => handlePlayClickCandidate(bubbleItem._videoIndex)}
                          >
                            &#9658;
                          </button>
                        )}
                        {bubbleItem.content}
                      </div>
                    );
                  }
                });
              })()
            ) : (
              // If no transcript exists, render one bubble per video.
              videos && videos.length > 0 ? (
                videos.map((_, i) => (
                  <div className="bubbletemplate human" key={i}>
                    <button
                      className="play-button-speech"
                      onClick={() => handlePlayClickCandidate(i)}
                    >
                      &#9658;
                    </button>
                  </div>
                ))
              ) : (
                <div>No transcript available.</div>
              )
            )}
          </div>
        )}
      </div>

      {isVideoPopupVisible && (
        <div className="video-overlay" onClick={closeVideoPopup}>
          <div className="video-popup" onClick={(e) => e.stopPropagation()}>
            <div className="video-popup-content">
              <video controls autoPlay src={videoUrl} />
            </div>
          </div>
        </div>
      )}
    </aside>
  );
};

export default CandidateInfo;
