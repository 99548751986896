import React, { useEffect, useState, useRef } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import videoFile from './stockJobOfferVideo.mp4';
import { Superpowers } from './components/superpowers';
import './App.css';
import './home_page.css';
import manInterviewing from './maninterviewing.png';
import statsPic from './statspic.png';
import scrollDownArrow from './scrollIcon.png';
import TextScramble from './textscramble.js';
import myImage from './man 1.png';
import myImage2 from './dragon 1.png';
import myImage3 from './manreview 1.png';
import myImage4 from './manriding 2.png';
import myImage5 from './Union.png';
import myImage6 from './Union-2.png';
import myImage7 from './Union-3.png';
import myImage8 from './Union-4.png';
import myImage9 from './Union-5.png';
import myImage10 from './demo.png';
import myImage11 from './watchDEMO.png';

function HomePage() {
  const headerRef = useRef(null);
  const observerRef = useRef(null);
  const playerRef = useRef(null);
  const finalGridRef = useRef(null); // Add a ref for the final grid element
  const [selectedTool, setSelectedTool] = useState('AI Interview');

  const handleButtonClick = (tool) => {
    setSelectedTool(tool);
  };

  const getOverlayContent = () => {
    if (selectedTool === 'AI Interview') {
      return {
        title: 'Safira AI Interview',
        description:
          'Conversational AI interview that ensures every candidate gets heard. Know your candidates before moving forward. Get high-quality personality trait feedback on each applicant.',
      };
    } else if (selectedTool === 'Resume Screener') {
      return {
        title: 'Safira Resume Screener',
        description:
          'Effortlessly screen resumes at scale to identify top candidates without the risk of losing applicants. Get detailed insights and recommendations to streamline your hiring process.',
      };
    }
  };

  const { title, description } = getOverlayContent();

  useEffect(() => {
    const targets = document.querySelectorAll('.contentBody');
    const checkVisibility = () => {
      targets.forEach(target => {
        const rect = target.getBoundingClientRect();
        const fifthHeight = rect.height / 5;
        const elementMiddle = rect.top - fifthHeight;
        const bottomOfViewport = window.innerHeight;
        if (elementMiddle <= bottomOfViewport && !target.classList.contains('scrambled')) {
          target.classList.add('visible', 'scrambled');
          const fx = new TextScramble(target);
          const text = target.getAttribute('data-text');
          fx.setText(text).then(() => {
            console.log('Scramble complete!');
          });
        }
      });
    };
    window.addEventListener('scroll', checkVisibility);
    checkVisibility();
    return () => window.removeEventListener('scroll', checkVisibility);
  }, []);

  useEffect(() => {
    const navbar = document.querySelector('.menu-bar');
    if (navbar) {
      navbar.classList.remove('hide');
    }
  }, []);

  useEffect(() => {
    const handleIntersection = (entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('visible');
        } else {
          entry.target.classList.remove('visible');
        }
      });
    };

    observerRef.current = new IntersectionObserver(handleIntersection, {
      threshold: [0.25]
    });

    const targets = document.querySelectorAll('.info-text, .infopic, .contentHeader, .contentBody');
    targets.forEach(target => {
      observerRef.current.observe(target);
    });

    return () => {
      if (observerRef.current) {
        observerRef.current.disconnect();
      }
    };
  }, []);

  useEffect(() => {
    const tag = document.createElement('script');
    tag.src = "https://www.youtube.com/iframe_api";
    const firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

    window.onYouTubeIframeAPIReady = () => {
      playerRef.current = new window.YT.Player('youtube-video', {
        events: {
          'onReady': onPlayerReady
        }
      });
    };

    const onPlayerReady = (event) => {
      document.querySelector('.play-button').addEventListener('click', () => {
        event.target.playVideo();
      });
    };
  }, []);

  const scrollToBottom = () => {
    finalGridRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  const superpowers = ['Team.', 'Decisions.', 'Hiring.'];

  return (
    <Router>
      <div>
      <div className="hero-container">
          <div className="video-container">
            <video autoPlay loop muted playsInline>
              <source src={videoFile} type="video/mp4" />
            </video>
          </div>

          <Superpowers superpowers={superpowers} className="superpowers-text" />

          <button onClick={scrollToBottom} className="watch-demo-button">
            Watch Demo
          </button>

          <img src={scrollDownArrow} alt="Scroll down" className="scroll-arrow" />
        </div>

        <div className="mission-section">
          <div className="mission-container">
            <div className="mission-left">
              <h2 className="mission-title">Our Mission</h2>
              <h3 className="mission-subtitle">Making hiring fair and easy for everyone.</h3>
            </div>
            <div className="mission-right">
              <p className="mission-description">
                The hiring landscape is evolving, with more job seekers entering the market every year.
                In a world where AI tools can apply to thousands of jobs in a single click, tools like Safira are essential to
                efficiently and fairly screening candidates without compromising integrity. <br />
                <br />
                Our mission is simple yet transformative: to ensure every candidate has a fair chance to showcase their potential
                and share their story. We envision a future where job applications become obsolete. With Safira, businesses can
                discover tailored candidate profiles effortlessly, while candidates can demonstrate their capabilities through a
                concise 10-minute video detailing their potential and capabilities. Together, we’re redefining recruitment.
              </p>
            </div>
          </div>
        </div>

        <div className="black-section-container">

          <div className="black-section-row">
            <div className="black-section-video-wrapper">
              <video className="black-section-video" autoPlay loop muted playsInline>
                <source src="/safirapromovid.mp4" type="video/mp4" />
              </video>
            </div>
            <div className="black-section-text">
              <h2 className="black-section-title">
                Learn every candidate's story. Interview at scale.
              </h2>
            </div>
          </div>

          <div className="black-section-row">
            <div className="black-section-text">
              <h2 className="black-section-title">
                Create an interview in minutes. Send it to thousands.
              </h2>
            </div>
            <div className="black-section-video-wrapper">
              <video className="black-section-video" autoPlay loop muted playsInline>
                <source src="/fractal ai demo 3 - HD 720p.mov" type="video/mp4" />
              </video>
            </div>
          </div>

          <div className="black-section-row">
            <div className="black-section-video-wrapper">
              <video className="black-section-video" autoPlay loop muted playsInline>
                <source src="/fractal ai demo 2 - HD 720p.mov" type="video/mp4" />
              </video>
            </div>
            <div className="black-section-text">
              <h2 className="black-section-title">
                Get soft & hard skill feedback. Personalized insights.
              </h2>
            </div>
          </div>

        </div>
        <div className="ai-interview-section">
            <div className="ai-video-wrapper">
              <video autoPlay loop muted playsInline>
                <source src="/safiraballrolling.mp4" type="video/mp4" />
                Your browser does not support the video tag.
              </video>

              {/* Text Over Video (Desktop Only) */}
              <div className="ai-video-overlay-desktop">
                <h2 className="ai-video-title">{title}</h2>
                <p className="ai-video-description">{description}</p>
              </div>
            </div>

            {/* Buttons Row */}
            <div className="ai-buttons-row">
              <button
                className={`ai-overlay-button ${selectedTool === 'AI Interview' ? 'active' : ''}`}
                onClick={() => handleButtonClick('AI Interview')}
              >
                AI Interview
              </button>
              <button
                className={`ai-overlay-button ${selectedTool === 'Resume Screener' ? 'active' : ''}`}
                onClick={() => handleButtonClick('Resume Screener')}
              >
                Resume Screener
              </button>
            </div>

            {/* Text Below Buttons (Mobile Only) */}
            <div className="ai-video-overlay-mobile">
              <h2 className="ai-video-title">{title}</h2>
              <p className="ai-video-description">{description}</p>
            </div>

          </div>


          <div className="final-grid-element background-image" ref={finalGridRef} style={{ paddingBottom: '330px' }}>
            <div className="video-wrapper" style={{ backgroundColor: 'transparent', marginTop: '95px', marginLeft: '6px', transform: 'scale(0.8)', zIndex: 1 }}>
              <iframe
                id="youtube-video"
                className="youtube-iframe"
                src="https://www.youtube.com/embed/fJ1DRudiBns?si=PcaFk_4CDdiW9mTJ"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                style={{ backgroundColor: 'transparent', width: '100%', height: '500px' }} 
              ></iframe>
              <div className="play-button" onClick={() => playerRef.current.playVideo()}>
                ▶
              </div>
            </div>
            <div className="video-wrapper" style={{ backgroundColor: 'transparent', marginTop: '95px', marginLeft: '6px', transform: 'scale(0.8)', zIndex: 1 }}>
              <iframe
                id="youtube-video-2"
                className="youtube-iframe"
                src="https://www.youtube.com/embed/hW5vUvZEdRE?si=vnXdMUNy3mFBnwuo"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                style={{ backgroundColor: 'transparent', width: '100%', height: '500px' }} // Adjusted height
              ></iframe>
              <div className="play-button" onClick={() => playerRef.current.playVideo()}>
                ▶
              </div>
            </div>
            <a href="https://calendly.com/aman04shah/15-min-meeting?month=2024-07" className="schedule-demo-button" >
                Schedule a Demo
            </a>
          </div>
        </div>
    </Router>
  );
}

export default HomePage;