import React, { useState } from 'react';
import { useHistory } from 'react-router-dom'; // Importing useHistory
import { FaChevronRight, FaBriefcase, FaChartLine, FaStar, FaQuestionCircle, FaUser } from 'react-icons/fa';
import './businessSidebar.css';

const BusinessSidebar = ({ handleButtonClick, currentUser, company, activeSection, name, handleFooterClick }) => {
  const [selectedItem, setSelectedItem] = useState(null);
  const history = useHistory(); // Initialize useHistory

  const handleItemClick = (itemName) => {
    setSelectedItem(itemName);
    handleButtonClick(itemName);
  };

  return (
    <aside className="sidebar">
      <div className="sidebar-header">
        <h2>{selectedItem || "Positions"}</h2>
      </div>
      <nav className="sidebar-nav">
        <table>
          <tbody>
            <tr
              className={activeSection === 'positions' ? 'selected' : ''}
              onClick={() => handleItemClick('Positions')}
            >
              <td><FaBriefcase /></td>
              <td>Positions</td>
              <td><FaChevronRight /></td>
            </tr>
            <tr
              className={activeSection === 'candidates' ? 'selected' : ''}
              onClick={() => handleItemClick('Candidates')}
            >
              <td><FaChartLine /></td>
              <td>Candidates</td>
              <td><FaChevronRight /></td>
            </tr>
            <tr
              className={activeSection === 'starred' ? 'selected' : ''}
              onClick={() => handleItemClick('Starred')}
            >
              <td><FaStar /></td>
              <td>Starred</td>
              <td><FaChevronRight /></td>
            </tr>
            <tr
              className={activeSection === 'help' ? 'selected' : ''}
              onClick={() => handleItemClick('Help')}
            >
              <td><FaQuestionCircle /></td>
              <td>Help</td>
              <td><FaChevronRight /></td>
            </tr>
            <tr
              className={activeSection === 'resume screener' ? 'selected' : ''}
              onClick={() => handleButtonClick('Resume Screener')}
            >
              <td><FaBriefcase /></td>
              <td>
                <span className="resume-screener">Resume Screener</span> 
                <span className="beta-badge">Beta</span>
              </td>
              <td><FaChevronRight /></td>
            </tr>
          </tbody>
        </table>
      </nav>
      <button className="sidebar-footer" onClick={handleFooterClick}>
        <FaUser className="footer-icon" />
        <div className="user-details">
          <p className="user-name">{name || currentUser?.displayName || "User"}</p>
          <p className="user-company">{company || "Company"}</p>
        </div>
      </button>
    </aside>
  );
};

export default BusinessSidebar;
